import { Button } from "@mui/material"
import { ReactNode, useState } from "react"
import { NavLink } from "react-router-dom"
import './styles.scss'

interface IAlertsBox
{
    text?: string | JSX.Element | ReactNode
    icon?: string | JSX.Element | ReactNode
    buttoned?: boolean
    onCloseEvent?: any
}

interface IAlertBase extends IAlertsBox
{
    type: string
}

export function AlertBase({ text, icon, buttoned, type, onCloseEvent }: IAlertBase) {
    const [ closed, setClosed ] = useState(false)
    return (
        (!closed)? 
        <div>
            <div className={`alert alert-${type} ${buttoned? 'buttoned' : ''}`}>
                <div>{ icon || '' } {text}</div> {buttoned? <div className="align-middle"><i className="fas fa-times-circle" onClick={() => {
                    if(onCloseEvent)
                        onCloseEvent(!closed)
                    setClosed(!closed)
                }}></i></div> : null }
            </div>
        </div> : null 
    )
}

export function AlertDanger({ text, icon, onCloseEvent }: IAlertsBox)
{
    return (
        <AlertBase text={ text } icon={ icon } buttoned={ true } onCloseEvent={onCloseEvent} type="danger" />
    )
}

export function AlertSuccess({ text, icon, onCloseEvent }: IAlertsBox)
{
    return <AlertBase text={ text } icon={ icon } buttoned={ true } onCloseEvent={onCloseEvent} type="success" />
}

export function AlertNoNotifications()
{
    return (
        <div className="align-middle">
            <i className="far fa-bell-slash fa-6x inactive"></i>
            <h6 className="mt-4">No Notifications yet.</h6>
        </div>
    )
}

export function AlertNoHerdData(props?: any)
{
    return (
        <div className="align-middle pt-4">
            <i className="fas fa-file-upload fa-6x inactive"></i>
            <h6 className="mt-4">No cattle associated with this herd.</h6>
            <NavLink to={`/upload/${props.gid}`} ><Button variant="contained" color="primary" className="mt-2">Upload Herd</Button></NavLink>
        </div>
    )
}

export interface IAlertYinYang
{
    success: string
    error: string
    fullWidth?: boolean
}

export function AlertYinYang({ success, error, fullWidth }: IAlertYinYang)
{
    return (
        <div className={`alert-container ${fullWidth? 'full-span' : ''}`}>
            { success !== ''? <AlertSuccess text={ success } /> : null }
            { error !== ''? <AlertDanger text={ error } /> : null }
        </div>
    )
}