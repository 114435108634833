import { BootStrapSpinner } from '../views/spinner'
import './styles.scss'

type ILoaderRequest = {
    text?: string
    spinnerType?: string
}

export function Loader({ text, spinnerType }: ILoaderRequest)
{
    return (
        <div className='full-width loader-body'>
            <div>
                { (spinnerType === 'cpv')? <BootStrapSpinner /> : <div className='spinner' /> }
                { (text)? <div className='text-center med pulsar'>{ text }</div> : null }
            </div>
        </div>
    )
}