import { signOut } from "../components/logout"
import { environment } from "../environmentals/config"
import { decodeJwt, getToken } from "./token"

type IHttpClient = {
    "Access-Control-Allow-Headers": string,
    "Access-Control-Allow-Methods": string,
    'Content-Type': string,
    Authorization?: string
}


export default class HttpClient
{
    public type: string = 'application/json'
    private endpoint: string = environment.api

    private headers: IHttpClient = {
        "Access-Control-Allow-Headers": [
            "Content-Type",
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Methods",
            "Authorization",
            "X-Requested-With",
            "api-user-id"
        ].join(','),
        "Access-Control-Allow-Methods": [
            'POST',
            'GET',
            'PATCH',
            'PUT',
            'DELETE',
            'OPTION'
        ].join(','),
        'Content-Type': this.type
    }

    constructor()
    {
        // Checks if user token is valid
        const token = getToken()
        const jwt = decodeJwt(token)
        if(token) {
            // If the token is expired, sign out
            if(jwt.expired) {
                signOut()
            }
            // Assign the bearer token
            this.headers.Authorization =  `Bearer ${ token }`
        }
    }

    setHeaders(array: any)
    {
        this.headers = array
        return this
    }

    getHeaders()
    {
        return this.headers
    }

    setApiEndpoint(uri: string)
    {
        this.endpoint = uri
        return this
    }

    async get<T>(service?: string, body?: any): Promise<T>
    {
        let queryString = ''
        if(body) {
            queryString += '?'
            for (const k in body) {
                queryString += `${k}=${body[k]}`
            }
        }
        
        return fetch(`${this.endpoint}/${service}${queryString}`, {
            method: 'GET',
            headers: this.headers
        }).then(response => response.json())
    }

    async delete<T>(service: string, body: any = {}): Promise<T>
    {
        return fetch(`${this.endpoint}/${service}`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: this.headers
        }).then(response => response.json())
    }

    async post<T>(service: string, body?: any): Promise<T>
    {
        return fetch(`${this.endpoint}/${service}`, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(body)
        }).then(response => response.json())
    }

    async patch<T>(service: string, body?: any): Promise<T>
    {
        return fetch(`${this.endpoint}/${service}`, {
            method: 'PATCH',
            headers: this.headers,
            body: JSON.stringify(body)
        }).then(response => response.json())
    }

    static init(): HttpClient
    {
        return new HttpClient()
    }

    upload(service: string, body: FormData, method: string = 'POST'): Promise<Response>
    {
        return fetch(`${this.endpoint}/${service}?jwtToken=${getToken()}`, {
            method,
            body
        })//.then((res) => res.json());
    }
}

export const http = (type?: 'f' | 'b'): HttpClient => {
    const client: HttpClient = new HttpClient()
    if(type !== 'b')
        client.setApiEndpoint(environment.apiFrontend)
    return client
}