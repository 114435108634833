import { useContext, useEffect, useState } from "react"
import { Button, Select, MenuItem, TextField } from "@mui/material"
import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from '@mui/x-data-grid'
import { GroupLock, Herd, HerdAttribute } from "../../../services/herd-groups"
import { AlertNoHerdData } from "../../ui/views/alerts"
import { FasFaLock, FasFaPaperPlane, FasFaUnLock } from "../../ui/fontawesome"
import { AlertSuccess } from "../../ui/views/alerts"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { ModalContent } from "./modal-content"
import { CattleFormSingleViewDto } from "../../../dto/animal-record"
import { CattleGroupDto } from "../../../dto/cattle-group"
import { CattleRecordFormSingleView } from "./cattle-form-single-record-view"
import { UploadCsv } from "../../upload"
import { isAdmin } from "../../../helpers/account"
import { ModalContext } from "../../../providers/modal.provider"
import { HerdGroupsContext } from "../../../providers/herd-groups.provider"
import { IHerdAttribute, IHerdAttributeResponse } from "../../../interfaces/herd-cattle.interface"
import { validateDate, validateEid } from "../../../helpers/util.helper"
import IComponentState, { ComponentStateDto } from "../../../interfaces/component-state.interface"
import ButtonBack from "../../ui/buttons/button-back"
import ButtonAdmin, { IconAdmin } from "../../ui/buttons/button-admin"
import AdminValidation from "../admin-validation"
import DropdownGroupStatus from "../../dropdown-group-status"
import __ from "../../../services/translator"
import './styles.scss'
import { ipfsUri } from "../../../helpers/ipfs.helper"

export function CattleRecords()
{
    const [ gridLockState, setGridLockState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ gidLockPrompt, setGidLockPrompt ] = useState(false)
    const [ gridIsEditable, setGridIsEditable ] = useState(false)
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ animal, setAnimal ] = useState<CattleFormSingleViewDto>(new CattleFormSingleViewDto({}))
    const [ update, setUpdate ] = useState(false)
    const [ uploadNew, setUploadNew ] = useState(false)
    const [ search, setSearch ] = useState<string>('')
    const [ searched, setSearched ] = useState<boolean>(false)
    const [ newAnimal, setNewAnimal ] = useState<boolean>(false)

    const { groups, gidLocked, setGid, setGidLocked, herdState, herd, setHerd, setRefreshHerd, gid } = useContext(HerdGroupsContext)
    const { setModalActive, setModalBody, setModalTitle, setModalEvent, setModalAttr } = useContext(ModalContext)
    
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
    const [ dataTableTarget, setDataTableTarget ] = useState('')

    // const toHerdState = (ready: boolean = false, loading: boolean = false) => setHerdState({ ready, loading })

    const dateWidth = 120

    const dataTableColumns: GridColDef[] = [
        // {field: 'id', headerName: 'ID' },
        // {field: 'gid', headerName: 'Group Id' },
        {field: 'ranch_name', headerName: 'Ranch', width: 300, editable: false },
        {field: 'eid', headerName: 'EID', width: 200, editable: !gidLocked, preProcessEditCellProps: validateEid },
        {field: 'breed', headerName: 'Breed', width: dateWidth, editable: false },
        {field: 'tag_color', headerName: 'Tag Color', width: dateWidth, editable: !gidLocked },
        {field: 'visual_tag', headerName: 'Visual Tag', width: dateWidth, editable: !gidLocked },
        {field: 'location', headerName: 'Location', width: dateWidth, editable: false },
        {field: 'birth_date', headerName: 'Birth', width: dateWidth, editable: !gidLocked, preProcessEditCellProps: validateDate},
        // {field: 'death_date', headerName: 'Died', width: dateWidth, editable: true, preProcessEditCellProps: validateDate },
        // {field: 'harvest_date', headerName: 'Harvested', width: dateWidth, editable: true, preProcessEditCellProps: validateDate },
        {field: 'cid', headerName: 'CID', width: 80, editable: false, renderCell: (e: any) => {
            return (
                e.value? <a href={ipfsUri(e.value)} target="_blank" rel="no-referrer">IPFS</a> : ''
            )
        } },
        // {field: 'decentralized_at', headerName: 'On Chain', width: dateWidth, editable: false },
        {field: 'action', headerName: 'Action', width: 80, editable: false, renderCell: (params: any) => {
            return (
                gidLocked && !isAdmin()? null : <Button onClick={() => {
                    setUpdate(true)
                    setAnimal(params.row)
                }}>{isAdmin()? <IconAdmin text='' /> : <i className="fas fa-edit"></i>}</Button>
            )}
        }
    ]

    

    // Select a herd group
    const selectGroup = (e: any) => {
        const selected = e.target.value
        // Set the current group id
        setGid(selected)
        // Load the herd from the group
        setRefreshHerd(true)
        // If the herd id is valid, set the current herd
        if(selected === '0') {
            // Reset the grid
            setGidLocked(false)
        } else {
            // Check to see if this herd is already committed to record
            const isEditable = !gidLocked
            // Allow row editability based on status
            setGridIsEditable(isEditable)
            // Enable/Disable the commit button
            setGidLocked(!isEditable)
        }
    }
    // Lock the herd and reset the group list
    const lockHerdGroup = () => {
        if(!gridLockState.ready) {
            if(!gridLockState.loading) {
                setGridLockState(arr => ({...arr, loading: true}))
                GroupLock(gid).then(() => {
                    // Allow the success to run
                    setSuccessMessage('You have successfully locked the herd. CattleProof will be notified to review. Thank you!')
                    // setGridLockState({ready: true, loading: false})
                    // // Reset the cattle groups
                    // setRefresh(true)
                })
            }
        }
    }
    // This is the admin modal content
    const validationModal = () => {
        setModalActive(true)
        setModalAttr({width: 'w-md'})
        setModalTitle(__('Admin Validation', 'admin_validation'))
        setModalBody(<AdminValidation
            onCancel={() => {
                setModalActive(false)
            }}
            gid={gid}
            group={getGroup(gid)}
        />)
        if(setModalEvent)
            setModalEvent(() => () => {
                setModalActive(false)
            })
    }
    // Get group from gid
    const getGroup = (gid: string): CattleGroupDto => {
        let group = new CattleGroupDto({})
        groups.groups?.map((v) => {
            if(gid === v.ID)
                group = new CattleGroupDto(v)
            return null
        })
        return group
    }

    const submitSearch = (search?: string) => {
        Herd(gid, search).then(r => {
            // set the rows
            if(r.success) {
                // Set the rows
                setHerd(r.herd.map((v) => new CattleFormSingleViewDto(v)))
            }
        })
    }

    const tableView = ((herdState.ready && herd.length > 0) || search) && (!update && !uploadNew)

    const resetView = () => {
        setNewAnimal(false)
        setModalActive(false)
        setRefreshHerd(true)
    }

    const lockHerd = () => {
        setGidLocked(true)
        lockHerdGroup()
        setModalActive(false)
        setGidLockPrompt(false)
    }

    // Update single row/column attributes
    const updateAttribute = ({gid, rowId, field, value}: IHerdAttribute) => {
        HerdAttribute<IHerdAttributeResponse>(gid, rowId, field, value).then(() => {
        })
    }

    if(tableView && searched && search === '') {
        setRefreshHerd(true)
        setSearched(false)
    }
    
    useEffect(() => {
        if(newAnimal) {
            setModalBody(<CattleRecordFormSingleView
                new={true}
                reloadHerd={ resetView }
                gid={gid}
                animal={ new CattleFormSingleViewDto({}) }
                setUpdate={setNewAnimal}
            />)
            setModalTitle("Create Animal")
            if(setModalEvent)
                setModalEvent(() => resetView )
            setModalActive(true)
        }
    }, [newAnimal])
    
    useEffect(() => {
        if((gidLockPrompt && gid !== '0') && !update) {
            setModalBody(<ModalContent lockHerdGroup={ lockHerd } setGidLockPrompt={() => {
                setModalActive(false)
                setGidLockPrompt(false)
            }} />)
            setModalTitle("Confirm Locking For Review")
            if(setModalEvent)
                setModalEvent(() => () => {
                    setModalActive(false)
                    setGidLockPrompt(false)
                })
            setModalActive(true)
        }
    }, [gidLockPrompt])


    return (
        <div id="cattle-records">
            {/* { (errorMessage !== '' && typeof errorMessage !== "undefined")? <AlertDanger text={errorMessage} /> : '' } */}
            { (successMessage !== '')? <div className="mb-1"><AlertSuccess icon={<FasFaPaperPlane />} text={ successMessage } /></div> : '' }
            { !update? 
            <div className="mb-1 gapped d-flex flex-row button-shelf">
                <Select labelId="group-name" id="group-name" value={gid} label="Groups Available" onChange={selectGroup}> 
                    <MenuItem key={0} value={'0'} selected>Select Calving Group</MenuItem>
                    { groups.groups?.map((v) => {
                        const row = new CattleGroupDto(v)
                        if(typeof row.ID !== "undefined" && row.ID !== null) {
                            return (
                                <MenuItem key={ row.ID } value={ row.ID }>
                                    <DropdownGroupStatus row={row} />
                                </MenuItem>
                            )
                        } else {
                            return null
                        }
                    })}
                </Select>
                {isAdmin()? null :
                    (gid !== '0' && herd.length > 0)? <Button color="primary" className="py-3" variant="outlined" disabled={gidLocked} onClick={() => setGidLockPrompt(true)}>{gidLocked? <FasFaLock /> : <FasFaUnLock /> }&nbsp;{gidLocked? 'Locked' : 'Commit' }</Button> : null 
                }

                { ((herdState.ready && gid !== '0' && !update) && !gidLocked) && !isAdmin()? <Button className="bold py-3" variant="outlined" onClick={() => {
                    if(uploadNew) {
                        setGid(gid)
                        setRefreshHerd(true)
                    }
                    setUploadNew(!uploadNew)}
                }>{uploadNew? <ButtonBack text="Done" /> : <span><i className="fas fa-redo"></i> Replace Herd</span>}</Button> : null }

                { (herdState.ready && gid !== '0' && !update && isAdmin())? <ButtonAdmin variant="outlined" className="bold" onClickEvent={validationModal}></ButtonAdmin> : null }

            </div>
            : null }
            
            { herdState.loading? <div className="align-middle p-5 m-5"><BootStrapSpinner /></div> : '' }

            { update? <CattleRecordFormSingleView reloadHerd={ () => { setRefreshHerd(true) } } animal={ animal } setUpdate={setUpdate} /> : null }

            { (uploadNew && !gidLocked)? <UploadCsv title="" group={ gid } success={ setSuccessMessage }  /> : null }

            { tableView ? // If there are rows the view AND we are not trying to update
            <div>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    submitSearch(search)
                }}>
                    <div className="d-flex gapped p-3 bkg-light-grey rounded border border-secondary mt-3 search-box-container">
                        <TextField value={search} label="Search Herd Group" onChange={e => setSearch(e.target.value)} />
                        <Button variant="contained"  onClick={() => submitSearch(search)}><i className="fas fa-search"></i>Search</Button>
                        <Button variant="contained" disabled={search === ''} onClick={() => {
                            submitSearch('')
                            setSearch('')
                        }}><i className="fas fa-eraser"></i>Clear</Button>
                        { gridIsEditable && !isAdmin()? <Button variant="contained" onClick={() => setNewAnimal(true)}><i className="fas fa-plus"></i>Add Animal</Button> : null }
                    </div>
                </form>
                <div className="mt-3" style={ { height: 'auto' } }>
                    <DataGrid
                        //onProcessRowUpdateError={(e) => e}
                        rowModesModel={ rowModesModel }
                        onRowModesModelChange={ setRowModesModel }
                        onCellEditStart={(e) => {
                            setDataTableTarget(e.field)
                        }}
                        processRowUpdate={(e: GridRowModel) => {
                            updateAttribute({
                                field: dataTableTarget,
                                gid: gid,
                                rowId: e.ID,
                                value: e[dataTableTarget as keyof typeof e]
                            })
                            return e
                        }}
                        rows={ herd }
                        columns={ dataTableColumns }
                        checkboxSelection={false}
                    />
                </div>
            </div>
             : ((herdState.ready && gid !== '0' && !update && !uploadNew) && !gidLocked)? <AlertNoHerdData gid={ gid } /> : null }
        </div>
    )
}