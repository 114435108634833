import { FormLabel, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { onTextChangeEvent } from "../submodule.base"
import { ApplicationContext } from "../../../providers/application.provider"

interface IModuleComponentsTextFieldComponent
{
    title?: string
    required?: boolean
    name: string
    placeholder?: string
    defaultValue?: any
    label?: string
    className?: string
    disabled?: boolean
    readonly?: boolean
    replace?: string
    type?: string
    match?: string
    max?: number
    overRideEditable?: boolean
    masterDisabled?: boolean
}

export const ModuleComponentsTextFieldComponent = ({
    required,
    name,
    defaultValue,
    label,
    disabled,
    placeholder,
    replace,
    type,
    max,
    match,
    className,
    title,
    overRideEditable,
    masterDisabled
}: IModuleComponentsTextFieldComponent) => {
    const [ form, setForm ] = useState<any>({[name]: defaultValue || ''})
    const [ error, setError ] = useState<string>('')
    const { editable } = useContext(ApplicationContext)
    const [ data, setData ] = useState<any>(undefined)
    const [ firstRun, setFirstRun ] = useState<boolean>(true)
    useEffect(() => {
        if(firstRun && defaultValue) {
            setData(defaultValue)
            setFirstRun(false)
        } else {
            if(data) {
                setForm({[name]: data})
                setData('')
            }
        }
    })

    let isEditable = editable
    if(overRideEditable)
        isEditable = typeof disabled === "boolean"? !disabled : true

    if(typeof masterDisabled !== "undefined") {
        isEditable = !masterDisabled
    }

    const isRequired = (): boolean => {
        const r = typeof required === "boolean"? required : true
        if(!['password'].includes(type || 'text'))
            return r
        return (!r)? form[name] !== '' : r
    }

    const req = isRequired()

    return (
        <>
            { title? <FormLabel component="legend" className={`${req? 'required' : ''} ${!label? 'minus-mb' : ''}`}>{ title }</  FormLabel> : null }
            <TextField
                className={className || "mt-0"}
                label={label || ''}
                onChange={(e: any) => {
                    setData('')
                    if(error !== '')
                        setError('')
                    onTextChangeEvent(e, setForm, replace, max)
                } }
                value={ form[name] }
                name={ name }
                disabled={ !isEditable }
                required={ req }
                placeholder={placeholder || ''}
                type={type || 'text'}
                onBlur={() => {
                    if(match) {
                        if(!form[name].match(match)) {
                            setError(`Invalid ${label}`)
                            setForm({[name]: ''})
                        }
                    }
                }}
                helperText={error}
                error={error !== ''}
                fullWidth
            />
        </>
    )
}