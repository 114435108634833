import { DataGrid, GridColDef, GridRowModel, GridRowModesModel } from "@mui/x-data-grid"
import { useContext, useEffect, useState } from "react"
import { ModalContext } from "../../../providers/modal.provider"
import { useBreedsHook } from "../../../hooks/breeds.hook"
import { count, validateEid } from "../../../helpers/util.helper"
import { Button, TextField } from "@mui/material"
import { CattleRecordFormSingleViewComponent } from "../cattle-records/cattle-form-single-record-view.component"
import { IconAdmin } from "../../ui/buttons/button-admin"
import { isAdmin } from "../../../helpers/account"
import { Herd, HerdAttribute } from "../../../services/herd-groups"
import { CattleFormSingleViewDto } from "../../../dto/animal-record"
import { IHerdAttribute, IHerdAttributeResponse } from "../../../interfaces/herd-cattle.interface"
import { ComponentStateDto } from "../../../interfaces/component-state.interface"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { ipfsUri } from "../../../helpers/ipfs.helper"
import { cacheGet } from "../../../services/cache"
import OutputIcon from '@mui/icons-material/Output'
import { filterMuiColumns } from "../../../helpers/arrayworks.helper"

interface IAnimalEditorGridComponent
{
    isEditable: any
    setUpdate: any
    setHerd: any
    gid: string
    herd: any
    herdState: any
    update: any
    setRefreshHerd: any
    setGroupsState: any
    setGid?: any
    setHerdState?: any
    groupId?: string
}

export const AnimalEditorGridComponent = ({ 
    isEditable,
    setUpdate,
    setHerd,
    gid,
    herd,
    herdState,
    update,
    setRefreshHerd,
    setGroupsState
}: IAnimalEditorGridComponent) => {
    const [ search, setSearch ] = useState<string>('')
    const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
    const [ dataTableTarget, setDataTableTarget ] = useState('')
    const [ uploadNew ] = useState(false)
    const [ searched, setSearched ] = useState<boolean>(false)
    const [ newAnimal, setNewAnimal ] = useState<boolean>(false)
    const [ reload, setReload ] = useState<boolean>(false)

    const { setModalActive, setModalBody, setModalTitle, setModalEvent } = useContext(ModalContext)
    const { breedsState } = useBreedsHook()

    const gidLocked: boolean = !isEditable()
    const dateWidth = 120
    
    const dataTableColumns: GridColDef[] = [
        // {field: 'id', headerName: 'ID' },
        // {field: 'gid', headerName: 'Group Id' },
        { field: 'ranch_name', headerName: 'Ranch', flex: 1, minWidth: 250, editable: false },
        { field: 'location', headerName: 'Location', width: dateWidth, editable: false },
        { field: 'archived_at', headerName: 'Disposed', width: dateWidth, editable: false, renderCell: (e: any) => e.row.archived_at? <OutputIcon /> : '' },
        { field: 'breed', headerName: 'Breed', width: dateWidth, editable: false },
        { field: 'eid', headerName: 'PCT Id', flex: 1, minWidth: 250, editable: !gidLocked, preProcessEditCellProps: validateEid },
        { field: 'visual_tag', headerName: 'Non-PCT Id', flex: 1, minWidth: 250, editable: !gidLocked },
        { field: 'tag_color', headerName: 'Tag Color', minWidth: 80, editable: !gidLocked },
        // { field: 'birth_date', headerName: 'Birth', width: dateWidth, editable: !gidLocked, preProcessEditCellProps: validateDate },
        // {field: 'death_date', headerName: 'Died', width: dateWidth, editable: true, preProcessEditCellProps: validateDate },
        // {field: 'harvest_date', headerName: 'Harvested', width: dateWidth, editable: true, preProcessEditCellProps: validateDate },
        { field: 'cid', headerName: 'CID', minWidth: 40, editable: false, renderCell: (e: any) => {
            return (
                e.value? <a href={ipfsUri(e.value)} target="_blank" rel="no-referrer">IPFS</a> : ''
            )
        } },
        // {field: 'decentralized_at', headerName: 'On Chain', width: dateWidth, editable: false },
        { field: 'action', headerName: 'Action', width: 80, editable: false, renderCell: (params: any) => {
            return (
                gidLocked? null : 
                <Button onClick={() => {
                    setModalActive(true)
                    setModalTitle('Edit Animal')
                    if(setModalEvent)
                        setModalEvent(() => setUpdate)
                    setModalBody(
                        <CattleRecordFormSingleViewComponent
                            reloadHerd={ setUpdate }
                            animal={ params.row }
                            setUpdate={ setUpdate }
                        />
                    )
                }}>{isAdmin()? <IconAdmin text='' /> : <i className="fas fa-edit"></i>}</Button>
            )}
        }
    ]

    const submitSearch = (search?: string) => {
        Herd(gid, search).then(r => {
            // set the rows
            if(r.success) {
                // Set the rows
                setHerd(r.herd.map((v) => new CattleFormSingleViewDto(v)))
            }
        })
    }

    const tableView = ((herdState.ready && herd.length > 0) || search) && (!update && !uploadNew)
    
    // Update single row/column attributes
    const updateAttribute = ({gid, rowId, field, value}: IHerdAttribute) => {
        HerdAttribute<IHerdAttributeResponse>(gid, rowId, field, value).then(() => {
        })
    }

    if(tableView && searched && search === '') {
        setRefreshHerd(true)
        setSearched(false)
    }
    
    if(update) {
        setGroupsState({...ComponentStateDto, completed: false})
        setRefreshHerd(true)
        setUpdate(false)
    }

    const onCloseOutAddAnimalEvent = () => {
        setRefreshHerd(true)
        setNewAnimal(false)
    }
    
    useEffect(() => {
        if(reload) {
            setRefreshHerd(true)
            setReload(false)
        }
    }, [ reload ])

    useEffect(() => {
        if(newAnimal) {
            setModalBody(<CattleRecordFormSingleViewComponent
                isNew={true}
                reloadHerd={ onCloseOutAddAnimalEvent }
                gid={gid}
                animal={ new CattleFormSingleViewDto({}) }
                setUpdate={setNewAnimal}
            />)
            setModalTitle("Create Animal")
            if(setModalEvent)
                setModalEvent(() => () => {
                    onCloseOutAddAnimalEvent()
                    setModalActive(false)
                })
            setModalActive(true)
        }
    }, [ newAnimal ])
    
    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault()
                submitSearch(search)
            }}>
                <div className="d-flex gapped p-3 bkg-light-grey rounded border border-secondary mt-3 search-box-container ignore-nightshift">
                    <TextField value={search} label="Search Herd Group" onChange={e => setSearch(e.target.value)} className="ignore-nightshift" />
                    <Button variant="contained"  onClick={() => submitSearch(search)}><i className="fas fa-search"></i>Search</Button>
                    <Button variant="contained" disabled={search === ''} onClick={() => {
                        submitSearch('')
                        setSearch('')
                    }}><i className="fas fa-eraser"></i>Clear</Button>
                    { isEditable()? <Button variant="contained" onClick={() => setNewAnimal(true)}><i className="fas fa-plus"></i>Add Animal</Button> : null }
                    { count(herd) > 0? <a href={ `${import.meta.env.VITE_APIENDPOINT}/generate/csv/${gid}?jwtToken=${cacheGet('token')}&dl=1` } className="ignore-nightshift"><Button className="py-3" variant="outlined"><i className="fas fa-file-download"></i>&nbsp;Download Animals</Button></a> : null }
                </div>
            </form>

            <div className="mt-3" style={ { height: 'auto' } }>
                {breedsState.ready? 
                <DataGrid
                    getRowId={e => e.ID }
                    //onProcessRowUpdateError={(e) => e}
                    rowModesModel={ rowModesModel }
                    onRowModesModelChange={ setRowModesModel }
                    onCellEditStart={(e) => {
                        setDataTableTarget(e.field)
                    }}
                    processRowUpdate={(e: GridRowModel) => {
                        updateAttribute({
                            field: dataTableTarget,
                            gid: gid,
                            rowId: e.ID,
                            value: e[dataTableTarget as keyof typeof e]
                        })
                        return e
                    }}
                    rows={ herd }
                    columns={ filterMuiColumns<any>(dataTableColumns, herd, ['action']) }
                    checkboxSelection={false}
                /> : <BootStrapSpinner /> }
            </div>
        </div>
    )
}