import { memo, useContext } from "react"
import { FasFaFileImport } from "../../fontawesome"
import { NavLink } from "react-router-dom"
import { isAdmin } from "../../../../helpers/account"
import { ApplicationContext } from "../../../../providers/application.provider"
import { SSOHook } from "../../../../hooks/sso.hook"
import { empty } from "../../../../helpers/util.helper"
import { AccountContext } from "../../../../providers/account.provider"
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import __ from "../../../../services/translator"
import './styles.scss'
import { environment } from "../../../../environmentals/config"

export const NavSidebar = memo(function NavSidebar(props?: any)
{
    const { account } = useContext(AccountContext)
    const { setTriggerSso } = SSOHook()
    // const { setGroupsState, setGid } = useContext(HerdGroupsContext)
    const { setToggleForm } = useContext(ApplicationContext)
    // const [ token, setToken ] = useState<string>('')
    const admin = isAdmin()

    const sso = () => setTriggerSso(true)

    const toggleOffForm = () => {
        if(props.clickEvent)
            props.clickEvent()
        setToggleForm(false)
    }

    const isActive = (path: string) => window.location.pathname.match(new RegExp(`${path}`, 'gi'))? 'active' : ''
    return (
        <div className={`left-nav shadowed sidebar ${!admin? 'admin-grad' : 'bkg-corp-grad'} ${account?.account_type === 'buyer'? 'buyer-view' : ''}`}>
            <ul className="list-menu">
                { admin? <li className="bkg-corp-dark"><h6 className="text-white p-3"><i className="fas fa-binoculars"></i>&nbsp;<strong>ADMIN MODE</strong></h6></li> : null }
                { account?.account_type === 'buyer'? <li className="bkg-corp-dark txt-center text-center"><h6 className="text-white p-3"><SettingsApplicationsIcon />&nbsp;<strong>LIMITED FEATURES</strong></h6></li> : null }
                <li>
                    <NavLink className={`${isActive('group')} ${empty(window.location.pathname)? 'active' : ''}`} to='/' onClick={toggleOffForm}><FasFaFileImport />&nbsp;{__('My Groups', 'my_groups')}</NavLink>
                </li>
                {/* <li>
                    <NavLink to="/herd/groups/" onClick={() => {
                        setGid('0')
                        setGroupsState({ready: false, loading: false, completed: false})
                        props.clickEvent? props.clickEvent() :  null
                    } }><FasFaHatCowboySide />&nbsp;{__('Herds', 'herds')}</NavLink>
                </li> */}
                {/* <li>
                    <NavLink to="/transfers/" onClick={() => props.clickEvent? props.clickEvent() :  null }><FasFaTruck />&nbsp;{__('Disposition', 'disposition')}</NavLink>
                </li> */}
                <li>
                    <NavLink to="/certified-herds/" onClick={ toggleOffForm }><i className="fas fa-award"></i>&nbsp;{__('Certified Groups', 'certified_groups')}</NavLink>
                </li>
                <li>
                    <NavLink to="/disposition/" onClick={ toggleOffForm }><i className="fas fa-map-marker-alt"></i>&nbsp;{__('Disposition', 'disposition')}</NavLink>
                </li>
                <li>
                    <NavLink to="/settings/" onClick={ toggleOffForm }><i className="fas fa-cog"></i>&nbsp;{__('Settings', 'settings')}</NavLink>
                </li>
                <li>
                    <NavLink to="/explorer/" onClick={ toggleOffForm }><ScreenSearchDesktopIcon />&nbsp;{__('Explorer', 'explorer')}</NavLink>
                </li>
                <li>
                {!admin? <a href='#' onClick={sso}><i className="fas fa-user"></i>&nbsp;{__('Account', 'account')}</a> : <a href={ environment.account }><i className="fas fa-user"></i>&nbsp;Admin</a> }
                </li>
            </ul>
        </div>
    )
})