import { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { FasFaLock, FasFaPaperPlane, FasFaUnLock } from "../../ui/fontawesome"
import { AlertSuccess } from "../../ui/views/alerts"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { isAdmin } from "../../../helpers/account"
import { TemplateCattleProofBlock } from "../../../templates/cattleproof/block"
import { useHerdHook } from "../../../hooks/herd.hook"
import { AdminCertifyOpenModalButton } from "../../ui/buttons/button-admin"
import { ApplicationContext } from "../../../providers/application.provider"
import { groupStatusEnum } from "../../../enums/group.status.enum"
import { useGroupTypesHook, useHerdGroupsHook } from "../../../hooks/groups.hook"
import { AnimalEditorGridComponent } from "./animal-editor-grid.component"
import { LockHerdFromGridComponent } from "./lock-herd-from-grid.component"
import { useParams } from "react-router-dom"
import TemplatesCattleProofCenterBlock from "../../../templates/cattleproof/center-block"
import './styles.scss'

interface IAnimalEditorComponent
{
    groupId?: string
    setTemplateTitle?: any
}

export const AnimalEditorComponent = ({ groupId, setTemplateTitle }: IAnimalEditorComponent) => {
    const { groupid } = useParams()
    const [ gid, setGid ] = useState<string>(groupId || groupid || '')
    const [ successMessage ] = useState('')
    const [ update, setUpdate ] = useState(false)
    const [ toggleLock, setToggleLock ] = useState<boolean>(false)
    
    const { currentApp, setReload, formId, setFormId } = useContext(ApplicationContext)

    const { herdState, herd, setHerd, setRefreshHerd, setHerdState } = useHerdHook(gid)
    const { setGroupState } = useHerdGroupsHook()
    const { groupTypes, groupTypesState } = useGroupTypesHook()
    const id = currentApp?.answers?.ID

    const onLockEvent = () => {
        setToggleLock(true)
    }

    const isEditable = () => {
        if(isAdmin())
            return currentApp?.answers?.status === groupStatusEnum.pending
        return (currentApp?.answers?.status === groupStatusEnum.ready) || !currentApp?.answers?.status
    }
    
    const getType = () => {
        let t: string | JSX.Element = <BootStrapSpinner />
        groupTypes.map(r => {
            if(r.type_id === currentApp?.answers?.group_type)
                t = `My ${r.name}`
        })
        return t
    }

    if(update) {
        setRefreshHerd(true)
        setUpdate(false)
    }

    useEffect(() => {
        if(groupTypesState.ready && currentApp?.answers?.group_type)
            setTemplateTitle(getType())
    }, [ groupTypesState.ready, currentApp?.answers?.group_type ])

    useEffect(() => {
        if((herdState.ready && !currentApp?.answers && !formId && !groupId) && groupid) {
            setFormId(groupid)
            setReload(true)
        }
    }, [herdState.ready, formId, currentApp?.answers, groupId, groupid])

    return (
        <TemplateCattleProofBlock
            title=''
            description={'Edit your animals below. Not all fields are editable.'}
            onBackEvent={() => window.location.reload() }
        >
        <div id="cattle-records">
            { (successMessage !== '')? <div className="mb-1"><AlertSuccess icon={<FasFaPaperPlane />} text={ successMessage } /></div> : '' }
            { !herdState.ready? <BootStrapSpinner /> :
                isAdmin()? null :
                    (herd.length > 0)?
                        <Button color="primary" className="py-3" variant="outlined" disabled={ !isEditable() || toggleLock } onClick={() => onLockEvent()}>{!isEditable()? <FasFaLock /> : <FasFaUnLock /> }&nbsp;{ !isEditable()? 'Locked' : 'Commit' }</Button> : null 
            }
            { (herdState.ready && gid !== '' && !update && isAdmin())? <AdminCertifyOpenModalButton group={ currentApp.answers } /> : null }
            { herdState.loading? <div className="align-middle p-5 m-5"><BootStrapSpinner /></div> : '' }
            { herdState.ready && !toggleLock?
                <AnimalEditorGridComponent
                    herd={ herd }
                    gid={ gid }
                    setHerd={ setHerd }
                    herdState={ herdState }
                    setUpdate={ setUpdate }
                    isEditable={ isEditable }
                    update={ update }
                    setGroupsState={ setGroupState }
                    setRefreshHerd={ setRefreshHerd }
                    groupId={ gid }
                    setGid={ setGid }
                    setHerdState={ setHerdState }
                /> : null }
            { herdState.ready && toggleLock?
                <TemplatesCattleProofCenterBlock
                    addClass="mt-2"
                    startClass="start2 py-4 span3-lg"
                >
                    <LockHerdFromGridComponent
                        id={id}
                        onSuccessLock={ () => setToggleLock(false) }
                        onCloseLock={ () => setToggleLock(false) }
                        group={ currentApp.answers }
                        setGid={setGid}
                    />
                </TemplatesCattleProofCenterBlock>
                 : null }
        </div>
      </TemplateCattleProofBlock>
    )
}