import { useContext, useEffect, useState } from "react"
import { useFormControlHook } from "../../../hooks/form-control.hook"
import { AccountContext } from "../../../providers/account.provider"
import { useUploadsHook } from "../../../hooks/uploads.hook"
import { IResponse } from "../../../interfaces/response.interface"
import { environment } from "../../../environmentals/config"
import { cacheGet } from "../../../services/cache"
import { Box, Button, FormControl, FormLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material"
import { CustomTabPanel } from "./custom-tab-component"
import { GenericUploadFileFormComponent } from "../../upload"
import { BootStrapSpinner } from "../../ui/views/spinner"
import { UploadSupportDocsTableComponent } from "./upload-support-docs-table-component"
import { UploadGenericDocsTableComponent } from "../generic-upload-docs"
import { loop } from "../../../helpers/arrayworks.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import HttpClient from "../../../helpers/client"
import UserToolsTagManagerUploader from "../tag-manager/uploader.component"

interface IUploadSupportDocsComponent
{
    group: any
    onCancelEvent: any
}

export const AdminGeneticsBaseComponentOptionsService = (setState: (state: IComponentState<any>) => void) => (new HttpClient()).get<IResponse<any>>(`genetics/upload/list`).then((r: any) => {
    if(r.success) {
        setState({...ComponentStateReadyDto, data: r.data})
    } else {
        throw new Error(r.error || 'An error occurred while fetching genetic document types.');
    }
})

export const UploadSupportDocsComponent = ({ group, onCancelEvent }: IUploadSupportDocsComponent) => {
    const [ form, setForm ] = useState<any>({})
    const [ msg, setMsg ] = useState({type: true, msg: ''})
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ value, setValue ] = useState<number>(0)
    const [ geneticUploadState, setGeneticUploadState ] = useState<IComponentState<any[]>>({...ComponentStateDto, data: []})
    const [ geneticMenuState, setGeneticMenuState ] = useState<IComponentState<any[]>>({...ComponentStateDto, data: []})
    //const [ tagAllowed, setTagAllowed ] = useState<boolean>(false)

    const { formControl, formControlState } = useFormControlHook()

    const { account } = useContext(AccountContext)
    const { setGid, gidState, uploads, setUploads, setGidState } = useUploadsHook()

    const setGeneticUploads = (data: any) => {
        setGeneticUploadState({...ComponentStateReadyDto, data: data})
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(!event)
            console.log('no event')
      setValue(newValue);
    };
  
    const reset = () => {
      const f: any = document.getElementById('file-input')
      if(f)
        f.value = ''
    }

    const getGeneticDocs = () => {
        if(!geneticUploadState.loading) {
            setGeneticUploadState(ComponentStateLoadingDto)
            return (new HttpClient()).get<IResponse<any>>(`upload/genetics/fetch`).then((r: any) => {
                if(r.success) {
                    setGeneticUploads(r.data);
                } else {
                    setGeneticUploadState(ComponentStateReadyDto)
                    throw new Error(r.error || 'Unable to fetch genetic documents.');
                }
            })
        }
    }
    
    const setFileAcceptedService = (ID: string, status: string) => {
        if(!geneticUploadState.loading) {
            setGeneticUploadState(ComponentStateLoadingDto)
            return (new HttpClient()).patch<IResponse<any>>(`upload/genetics/status/${ID}`, {status}).then((r: any) => {
                if(r.success) {
                    setGeneticUploads(r.data);
                } else {
                    setGeneticUploadState(ComponentStateReadyDto)
                    throw new Error(r.error || 'Unable to fetch genetic documents.');
                }
            })
        }
    }

    const deleteGeneticDocs = (ID: string) => {
        if(!geneticUploadState.loading) {
            setGeneticUploadState(ComponentStateLoadingDto)
            return (new HttpClient()).delete<IResponse<any>>(`upload/${ID}`).then((r: any) => {
                if(r.success) {
                    getGeneticDocs();
                } else {
                    setGeneticUploadState(ComponentStateReadyDto)
                    throw new Error(r.error || 'Unable to delete genetic documents.');
                }
            })
        }
    }
    
    const downloadGeneticDocs = (ID: string) => {
        if(!geneticUploadState.loading) {
            setGeneticUploadState({...ComponentStateLoadingDto, data: geneticUploadState.data})
            return (new HttpClient()).get<IResponse<any>>(`upload/genetics/download/${ID}`).then((r: any) => {
                if(r.success) {
                    setGeneticUploadState({...ComponentStateReadyDto, data: geneticUploadState.data})
                    window.location.href = r.data
                } else {
                    setGeneticUploadState(ComponentStateReadyDto)
                    throw new Error(r.error || 'Unable to delete genetic documents.');
                }
            })
        }
    }

    const httpClient = async (formData: any) => await fetch(`${environment.apiFrontend}/media/`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${cacheGet('token')}`,
        'X-APP-UID': account.ID,
        'X-APP-GID': group.ID,
        'X-APP-DESCRIPTION': form.description
      } 
    }).then(r => r.json())

    const onSubmitEvent = (e: any) => {

      e.preventDefault();
      setState(ComponentStateLoadingDto)
      setMsg({type: true, msg: ''})
      const formData = new FormData(e.target);
      const f: any = {...form}
      //const formValues = Object.fromEntries(formData.entries());
      const fileInput: any = document.getElementById('file-input');
      //loop(formValues, (v: any, k: any) => k !== 'upload'? formData.append(k, v) : null)
      if(fileInput && fileInput.files) {
          formData.append('upload', fileInput?.files[0] || '')
          formData.delete('file')
          delete f.file
      }

      loop(f, (v: any, k: any) => formData.append(k, v))

      httpClient(formData).then(r => {
        if(r.success) {
          fileInput.value = ''
          setMsg({type: true, msg: 'Upload successful'})
          setForm({file: ''})
          reset()
        } else {
          setMsg({type: false, msg: r.error})
        }
        setState(ComponentStateReadyDto)
        setGidState(ComponentStateDto)
      })
    }

    const a11yProps = (index: number) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    useEffect(() => {
        setGid(group.ID)    
    }, [])

    useEffect(() => {
        if(!geneticUploadState.ready) {
            getGeneticDocs()
        }
    }, [ geneticUploadState.ready ])

    useEffect(() => {
        if(geneticUploadState.ready && !geneticMenuState.ready) {
            if(!geneticMenuState.loading) {
                setGeneticMenuState(ComponentStateLoadingDto)
                AdminGeneticsBaseComponentOptionsService(setGeneticMenuState)
            }
        }
    }, [ geneticMenuState.ready, geneticUploadState.ready ])

    const getOptions = () => {
        return geneticMenuState?.data?.map((v: any) => {return {
            name: v.attr_name,
            label: v.attr_description,
            accept: v.attr_content.map((v: string) => `.${v}`)
        }})
    }

    const selectionIsReceipt: boolean =  ['pct_tag_bag_photo', 'pct_tag_receipt'].includes(form.description)

    return (
    <div className="border p-4 rounded mb-4">
        <h6 className="block-header uppercase"><strong><i className="far fa-file-alt"></i>&nbsp;<i className="fas fa-long-arrow-alt-right"></i>&nbsp;<i className="far fa-hdd"></i>&nbsp;{ group.group_name }</strong></h6>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Current" {...a11yProps(0)} />
                <Tab label="Upload Documents" {...a11yProps(1)} />
                <Tab label="Genetic Test Documents" {...a11yProps(2)} />
            </Tabs>
        </Box>
        
        <CustomTabPanel value={value} index={2}>
            { geneticMenuState?.ready?
             <GenericUploadFileFormComponent
                service={`${environment.api}/upload/`}
                options={ getOptions() || []}
            ><></></GenericUploadFileFormComponent> : null }
        </CustomTabPanel>

        <CustomTabPanel value={value} index={0}>
            <Typography variant="h6" className="mt-4">Operation Documents</Typography>
            { !gidState.loading? <UploadSupportDocsTableComponent {...{setGid, group, gidState, uploads, setUploads, setGidState}} /> : <BootStrapSpinner /> }
            <Typography variant="h6" className="mt-4">Genetic Documents</Typography>
            { geneticUploadState.ready?
            <UploadGenericDocsTableComponent
                downloadService={ downloadGeneticDocs }
                deleteService={ deleteGeneticDocs }
                setFileAcceptedService={ setFileAcceptedService }
                uploads={ geneticUploadState?.data || [] }
                setUploads={ getGeneticDocs }
            /> : <BootStrapSpinner /> }
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
            <form id="upload-form" encType="multipart/form-data" onSubmit={onSubmitEvent}>
                <FormControl className="col-count- gapped">
                        { (msg.msg !== '')? <div className={`alert alert-${msg.type? 'success' : 'danger'}`}>{msg.msg}</div> : null }
                        <FormLabel component="legend" className='required'>What kind of upload is this?</FormLabel>
                        <div className="col-count-2 gapped col-c1-md gapped">
                            
                            { !formControlState.ready?
                            <BootStrapSpinner /> :
                            <Select
                                value={form.description || ''}
                                required
                                onChange={(e: any) => {
                                    setForm((arr: any) => ({...arr, description: e.target.value }))
                                }}
                            >
                                {
                                    loop(formControl?.upload_ev_list, (v: any, k: any) => (
                                    <MenuItem key={k} value={ v.menuName }>{ v.menuVal }</MenuItem>
                                    ))
                                }
                            </Select>
                            }

                            { selectionIsReceipt && (
                                <div className="start1 border p-4">
                                    <p>Please add the tags on your receipt into our system so we can verify you are the owner of the tags in this group. You must upload all receipts that cover animals in this group.</p>
                                    <UserToolsTagManagerUploader gid={group.ID} useForm={ false } />
                                </div>
                            )}

                            <div className={ selectionIsReceipt? 'start1' : '' }>
                                <TextField
                                    type="file"
                                    name="file"
                                    id="file-input"
                                    inputProps={{
                                        accept: ".csv, .pdf, .jpg, .png, .jpeg"
                                    }}
                                    required
                                    onChange={(e: any) =>{
                                        setMsg({msg: '', type: true})
                                        setForm((arr: any) => ({...arr, file: e.target.value }))
                                    }}
                                    />
                            </div>
                        </div>
                </FormControl>
                
                <div className="align-middle mt-3">
                { !state.loading? 
                <div className="d-flex justify-content-center gapped">
                    <Button type='submit' className='corp' variant='contained' disabled={(!form.file || form.file === '') || form.description === ''}><i className="fas fa-arrow-circle-up"></i>&nbsp;Upload</Button>
                </div> : 
                <BootStrapSpinner />
                }
                </div>
            </form>
        </CustomTabPanel>

        <div className="align-middle">
            { onCancelEvent? <Button variant='outlined' onClick={onCancelEvent}><i className="fas fa-chevron-left"></i>&nbsp;Back</Button> : null }
        </div>
    </div>
    )
}