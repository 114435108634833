import { Button } from "@mui/material"
import { CowHeadIcon } from "../fontawesome"
import { ReactNode, useContext } from "react"
import { ApplicationContext } from "../../../providers/application.provider"
import { useNavigate } from "react-router-dom"
import __ from "../../../services/translator"

interface ICreateNewGroupButtonComponent
{
    onClickEvent?: any
    resetGroup: any
    disabled?: boolean
    title?: string | JSX.Element
}

export const CreateNewGroupButtonComponent = ({ onClickEvent, disabled, title }: ICreateNewGroupButtonComponent) => {
    const nav = useNavigate()
    const { setClearForm } = useContext(ApplicationContext)

    const defaultEvent = () => {
        //setToggleForm(true)
        setClearForm(true)
        // setFormContent(<ModuleComponent resetGroup={ resetGroup } />)
        nav('/create/group/')
    }

    return (
        <Button
            disabled={ disabled }
            variant="outlined"
            onClick={ () => {
                if(onClickEvent) {
                    onClickEvent(() => defaultEvent())
                } else {
                    defaultEvent()
                }
            } }
        >
            <CowHeadIcon color="blue" />&nbsp;{ title || 'Create Group'}
        </Button>
    )
}

export interface IButtonAny
{
    text?: string | JSX.Element | ReactNode
}

export const ButtonForward = ({ text }: IButtonAny) => {
    return (
        <span className="strong">
            <i className="fas fa-angle-double-right"></i>&nbsp;{ text || __('Next', 'next') }
        </span>
    )
}