import { useContext, useState } from "react"
import { AlertContext } from "../../../providers/alert.provider"
import { useParams } from "react-router-dom"
import { empty } from "../../../helpers/util.helper"
import { IResponse } from "../../../interfaces/response.interface"
import { CpvBlockHeader, CreateButtonBlock, FormSubmitButtonsBlock } from "../../../components/ui/blocks"
import { InputLabel, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material"
import { onChangeEventHelper } from "../../../helpers/form"
import { DispositionStatusEnum, DispositionTypeEnum } from "../../../enums/disposition.enum"
import { UiNotificationsAlertsComponent } from "../../../components/ui/notifications/alerts.component"
import { DispositionDestinationForm, GroupCheckBoxesComponent } from "./form-desination"
import { UserToolGridMenuComponent } from "../../../components/user-tools/grid-menu.tsx"
import { BootStrapSpinner } from "../../../components/ui/views/spinner/index.tsx"
import { dispositionIsEditableHelper } from "../helpers/index.tsx"
import { 
    DispositionCreateService,
    DispositionDeleteAnimalsService,
    DispositionGetAnimalsSearchService,
    DispositionGetAnimalsService,
    DispositionGetService,
    DispositionUpdateService
} from "../../../services/disposition.service"
import { AlertBase } from "../../../components/ui/views/alerts/index.tsx"
import { FasFaLock } from "../../../components/ui/fontawesome/index.tsx"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import TemplatesCattleProofCenterBlock from "../../../templates/cattleproof/center-block"
import AnimalTableEditorComponent from "../../../components/user-tools/animal-table"
import EditOffIcon from '@mui/icons-material/EditOff'
import OutputIcon from '@mui/icons-material/Output'

export const DispositionForm = ({ formData, setFormData, groups, backButtonEvent }: any) => {
    const [ toggle, setToggle ] = useState<boolean>(false)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ animalState ] = useState<IComponentState<any>>(ComponentStateReadyDto)
    const [ animalRows, setAnimalRows ] = useState<any[]>([])

    const { setMsg, setType, setAutoClose } = useContext(AlertContext)
    const { gid } = useParams()

    const getAnimals = () => DispositionGetAnimalsService(formData.ID)
    
    const searchAnimals = (search?: string) => DispositionGetAnimalsSearchService(formData.ID || '', search)

    const deleteAnimal = (row: any) => {
        return DispositionDeleteAnimalsService(formData.ID || '', row.hid).then((r) => {
            setFormData(r.data)
            setState(ComponentStateDto)
            getAnimals().then(r => {
                setAnimalRows(r.data)
                setState(ComponentStateDto)
            })
        })
    }

    if(!state.ready && (gid && gid !== 'create')) {
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            DispositionGetService(gid).then(r => {
                setFormData(r.data)
                setState(ComponentStateReadyDto)
            })
        }
    } else {
        if(!state.ready && !state.loading) {
            setState(ComponentStateReadyDto)
        }
    }

    const onSubmitEvent = (e: any) => {
        e.preventDefault()
        const action = (empty(formData?.ID))? DispositionCreateService(formData) : DispositionUpdateService(formData)
        action.then((r: IResponse<any>) => {
            if(r.success) {
                setFormData(r.data)
                setType(true)
                setMsg('Saved successfully.')
                backButtonEvent()
            } else {
                setMsg(r.error || 'An error occurred.')
            }
            setAutoClose(5000)
        })
    }

    const readOnly: boolean = formData?.status? !dispositionIsEditableHelper(formData?.status) || formData?.assigned?.length > 0 : false

    return (
        state.ready?
        <TemplatesCattleProofCenterBlock>
            { toggle?
                <AnimalTableEditorComponent
                    ready={ animalState.ready }
                    rows={ animalRows }
                    setRows={ setAnimalRows }
                    submitEvent={ () => {}}
                    backButtonEvent={() => setToggle(false)}
                    columns={[
                        { field: 'title', headerName: 'Group Name', flex: 1, minWidth: 250, editable: false },
                        { field: 'pct', headerName: 'PCT', flex: 1, minWidth: 250, editable: false },
                        { field: 'non_pct', headerName: 'Non-PCT', flex: 1, minWidth: 250, editable: false },
                        {
                            field: 'archived_at',
                            cellClassName: 'justify-content-center',
                            headerName: 'Disposed',
                            minWidth: 50,
                            editable: false,
                            renderCell: e => e.row?.archived_at? <OutputIcon style={{color: 'red'}} /> : ''
                        },
                        { field: 'action', headerName: '', cellClassName: 'justify-content-end', width: 60, flex: 1, editable: false, renderCell: (e: any) => {
                            return readOnly? <EditOffIcon /> : <UserToolGridMenuComponent deleteEvent={ () => deleteAnimal(e.row) } />
                        }},
                    ]}
                    onLoadEvent={ getAnimals }
                    submitSearch={ searchAnimals }
                />
                :
                gid === 'create' || state.ready? 
                <form action="#" onSubmit={ onSubmitEvent }>
                    { readOnly? <AlertBase text="This Disposition Group is currently being used in the Marketplace and can not be edited" type="info" icon={<FasFaLock />} /> : null}
                    <CpvBlockHeader
                        title={ `${gid === 'create'? 'Create a' : readOnly? 'Edit' : 'View'}  disposition group for transport or sale` }
                    >
                        <div className="col-count- gapped">
                            <TextField
                                name="title"
                                className="required"
                                inputProps={{readOnly}}
                                required
                                value={ formData?.title || '' }
                                label="Disposition Group Name"
                                onChange={ (e) => onChangeEventHelper(e, setFormData) }
                            />
                            <InputLabel>Add an optional description of this disposition group</InputLabel>
                            <TextareaAutosize
                                className="standard"
                                readOnly={readOnly}
                                name="description"
                                value={ formData?.description || '' }
                                placeholder="Example: For sale in the CPV marketplace"
                                onChange={ (e) => onChangeEventHelper(e, setFormData) }
                            />
                            <InputLabel className="required">What kind of disposition will this be?</InputLabel>
                            <Select
                                readOnly={ readOnly }
                                name="type"
                                value={ formData?.type || 0 }
                                required
                                onChange={(e: any) => {
                                    onChangeEventHelper(e, setFormData)
                                }
                            }>
                                <MenuItem value={ 0 }>Select</MenuItem>
                                <MenuItem value={ DispositionTypeEnum.sale }>Sale</MenuItem>
                                <MenuItem value={ DispositionTypeEnum.transport } disabled>Transportation</MenuItem>
                            </Select>
                            {/* <TextField
                                name="weight"
                                inputProps={{readOnly}}
                                className="required"
                                required
                                value={ formData?.weight || '' }
                                label="Weight Range Per Head"
                                onChange={ (e) => onChangeEventHelper(e, setFormData) }
                            /> */}
                            {/* { dispType === DispositionTypeEnum.sale? <FormMoneyComponent value={ formData?.price || '' } setFormData={ setFormData } label="Price for this group" required /> : null } */}
                        </div>
                    </CpvBlockHeader>
                    
                    { formData?.type === DispositionTypeEnum.transport? 
                    <div className="disposition-list">
                        <DispositionDestinationForm formData={ formData } setGroupForm={ setFormData } />
                    </div> : null }
                    { groups?.groups && (formData?.herd === 0 || !formData?.herd)?
                    <div className="disposition-animals">
                    <CpvBlockHeader
                        title="Add animals from your certified groups"
                        description={<>Below are the certified herd &amp; calving groups available to import into your disposition group.</>}
                    >
                        <GroupCheckBoxesComponent groups={ groups.groups } setForm={ setFormData } />
                    </CpvBlockHeader>    
                    </div> : null }

                    { (formData?.herd && formData?.herd > 0)?
                        <CpvBlockHeader
                            title={ `Animals (${ formData?.herd })` }
                            description="Animals that have been added to this Disposition Group"
                        >
                            <CreateButtonBlock createEvent={() => setToggle(true)} text={ `${readOnly? 'View' : 'Edit'} Animals` } />
                        </CpvBlockHeader>
                        : null }

                    <CpvBlockHeader
                        title="Activation"
                        description="By activating the group, you will make it available for other parts of the application to interact with. You would only disable it if you weren't ready to use it yet."
                    >
                        <InputLabel className="required">Enable or disable the Disposition Group.</InputLabel>
                        <Select
                            name="status"
                            readOnly={ readOnly }
                            value={ formData?.status || 0 }
                            required
                            onChange={(e: any) => {
                                onChangeEventHelper(e, setFormData)
                            }
                        }>
                            <MenuItem value={ 0 }>Select</MenuItem>
                            <MenuItem value={ 1 }>Inactive</MenuItem>
                            <MenuItem value={ 2 }>Active</MenuItem>
                            { readOnly? <MenuItem value={ DispositionStatusEnum.archived }>Archived</MenuItem> : null }
                            { readOnly? <MenuItem value={ DispositionStatusEnum.locked }>Locked</MenuItem> : null }
                        </Select>
                    </CpvBlockHeader>
                    <UiNotificationsAlertsComponent />
                    <FormSubmitButtonsBlock
                        backButton={{ onClickEvent: backButtonEvent }}
                        submitButton={{ disabled: (formData?.type === DispositionTypeEnum.transport && !formData?.destination_id || formData?.destination_id === '0') || readOnly }}
                        isSubmit
                    />
                </form> : <BootStrapSpinner />
            }
        </TemplatesCattleProofCenterBlock>
        : <BootStrapSpinner />
    )
}