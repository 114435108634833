import HttpClient from "../helpers/client";
import { IResponse } from "../interfaces/response.interface";

export function BreedsGetService(gid: string)
{
    return new Promise<any>((success, fail) => {
        try {
            return (new HttpClient()).get<any>(`breeds/${gid}`).then(r => success(r))
        } catch (error) {
            fail(error)
        }
    })
}

export interface IBreeds
{
    value: string
    label: string
}

interface IBreedsGetAvailableService
{
    breeds: IBreeds[]
    myBreeds: IBreeds[]
    allBreeds: IBreeds[]
}

export const BreedsGetAvailableService = () =>
{
    return (new HttpClient()).get<IResponse<IBreedsGetAvailableService>>('breeds/available')
}