import { useContext, useEffect, useState } from "react"
import { AlertContext } from "../../../providers/alert.provider"
import { useCountryHook } from "../../../hooks/regions.hook"
import { ContactsGetService, ContactsSaveService } from "../../../services/contact.service"
import { count, empty } from "../../../helpers/util.helper"
import { Loader } from "../../../components/ui/loader"
import { CpvBlockHeader } from "../../../components/ui/blocks"
import { Button, Checkbox, FormControlLabel, FormGroup, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material"
import { onChangeEventHelper, onCheckEventHelper, toFormRawArray } from "../../../helpers/form"
import { loop } from "../../../helpers/arrayworks.helper"
import { FormStateComponent } from "../../../components/form/state.component"
import { UiNotificationsAlertsComponent } from "../../../components/ui/notifications/alerts.component"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface"
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { BootStrapSpinner } from "../../../components/ui/views/spinner"

export const DispositionDestinationForm = ({ formData, setGroupForm }: any) => {
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ saveState, setSaveState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ did ] = useState<string>(formData?.ID || '')
    const [ form, setForm ] = useState<any>({})
    const [ contacts, setContacts ] = useState<any[]>([])
    const { setMsg, setType } = useContext(AlertContext)
    const { country } = useCountryHook()

    const onSubmitEvent = () => {
        if(!saveState.loading) {
            const f = {...form, did}
            f['type'] = 'disp'
            setSaveState(ComponentStateLoadingDto)
            ContactsSaveService(f).then(r => {
                if(r.error) {
                    setMsg(r.error || 'An error occurred')
                    setType(false)
                } else {
                    setForm(r.data)
                }
                setSaveState(ComponentStateDto)
                setState(ComponentStateDto)
            })
        }
    }

    if(!state.ready) {
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            ContactsGetService({type: 'disp'}).then(r => {
                setContacts(r.data)
                if(!empty(formData?.destination_id)) {
                    ContactsGetService({ destination_id: formData?.destination_id, type: 'disp' }).then(r => {
                        setForm(r.data)
                        setState(ComponentStateReadyDto)
                    })
                } else {
                    setState(ComponentStateReadyDto)
                }
            })
        }
    }

    useEffect(() => {
        if(empty(form?.destination_id)) {
            setForm({destination_id: '0'})
        } else {
            setGroupForm((arr: any) => ({...arr, destination_id: form?.destination_id}))
        }
    }, [ form?.destination_id ])

    return (
        state.loading? <Loader /> :
        <CpvBlockHeader
            addClass="mt-4"
            title={<><LocalShippingIcon />&nbsp;Destination</>}
        >
            { count(contacts) > 0?
                <>
                    <div>
                        <Select fullWidth onChange={(e: any) => {
                            onChangeEventHelper(e, setForm)
                            toFormRawArray({ destination_id: e.target.value}, setGroupForm)
                        }} name="destination_id" value={ form?.destination_id || '0' }>
                            <MenuItem value="0">{ form?.destination_id === '0'? 'Select Previous' : 'Create New' }</MenuItem>
                            { loop(contacts, (v: any) => (
                                <MenuItem key={v.ID} value={ v.ID }>{ v.full_name } { v.company? `(${v.company})` : '' }</MenuItem>
                            )) }
                        </Select>
                        {/* { form?.ID !== '0'? <div className="align-middle"><Button variant="outlined" className="mt-4">Use</Button></div> : null } */}
                    </div>
                    { (form?.ID !== '0')? null : <div className="my-3 align-middle">OR</div> }
                </> : null }

                { count(contacts) === 0 || empty(form?.destination_id)? 
                <>
                    <p className="mb-0"><strong className="uppercase">Create New Contact</strong></p>
                    <TextField
                        name="full_name"
                        value={ form?.full_name || ''}
                        label="Full Name"
                        required
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}
                    />
                    <TextField
                        name="company"
                        value={ form?.company || ''}
                        label="Company"
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}
                    />
                    <TextareaAutosize
                        className="standard"
                        name="address_1"
                        value={ form?.address_1 || ''}
                        placeholder="Address 1"
                        required
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}
                    />
                    <TextareaAutosize
                        className="standard"
                        name="address_2"
                        value={ form?.address_2 || ''}
                        placeholder="Address 2"
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}
                    />
                    <TextField
                        name="city"
                        value={ form?.city || ''}
                        label="City"
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}
                    />
                    <Select 
                        name="country"
                        value={ form?.country || '0' }
                        onChange={(e: any) => onChangeEventHelper(e, setForm)}>
                        <MenuItem value={"0"}>Select</MenuItem>
                        { country.ready? loop(country.data, (v: any) => (
                            <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>
                        )) : <BootStrapSpinner size="x-sm" /> }
                    </Select>

                    {form?.country === 'US'? 
                    <FormStateComponent
                        setForm={ setForm }
                        value={ form?.state || 'Select' }
                    /> :
                    empty(form?.state)? null :
                    <TextField
                        name="state"
                        value={ form?.state || ''}
                        label="State/Province"
                        onChange={(e: any) => onChangeEventHelper(e, setForm, (v: string) => {
                            return v.substring(0, 2).replace(/[^\w]/, '')
                        })}
                    />}

                    <TextField
                        name="post_code"
                        value={ form?.post_code || ''}
                        label="Post Code"
                        onChange={(e: any) => onChangeEventHelper(e, setForm, (v: string) => {
                            return v.substring(0, 6).replace(/[^\d\w]/, '')
                        })}
                    />
                    <UiNotificationsAlertsComponent />
                    <div className="align-middle">
                        <Button variant="outlined" onClick={ onSubmitEvent }>Save Destination</Button>
                    </div>
                </> : null }
        </CpvBlockHeader>
    )
}

export const DispositionValidGroups = ({ groups }: any) => {
    return (
        <div>
            { loop(groups, (v: any, k: number) => (
                <div key={`filtered-group-${k}`}>
                    <h5>{ v.group_name }</h5>
                    <p>{ v.id_description }</p>
                </div>
            ))}
        </div>
    )
}

interface IGroupCheckBoxesComponent
{
    groups: any[]
    setForm: CallableFunction
}

export const GroupCheckBoxesComponent: React.FC<any> = ({ groups, setForm }: IGroupCheckBoxesComponent) => {
    const [ formData, setFormData ] = useState<any>({})

  return (
    <FormGroup>
      { groups && groups.map((group: any) => group.decentralized === 1? (
        <FormControlLabel
            key={ group.ID }
            value={ formData[`import[${group.ID}]`] || false}
            control={
            <Checkbox
                checked={ formData[`import[${group.ID}]`] || false }
                onChange={(e: any) => {
                    onCheckEventHelper(e, setForm)
                    onCheckEventHelper(e, setFormData)
                }}
            />
            }
            name={ `import[${group.ID}]` }
            label={ `${group.group_name} (${group.herd_count} animals in group / ${group?.available_for_disposition || 0} remaining)` }
        />
        ) : null)}
    </FormGroup>
  )
}