import { ILockWindowModal } from "./herdlock.interface"
import { Button, Typography } from "@mui/material"
import { PreFlightWindow } from "./preflight-window"
import { ButtonForward } from "../../components/ui/buttons"
import { ContinuePreFlightWindow } from "./continue-preflight-window"
import { count } from "../../helpers/util.helper"
import { useState } from "react"
import ButtonBack from "../../components/ui/buttons/button-back"
import { environment } from "../../environmentals/config"

export const LockWindowModal = ({ preFlight, group, setGid, events }: ILockWindowModal) => {
    const [ wizard, setWizard ] = useState<string>('preflight')
    const preFlightData = () => preFlight.data
    const certs = () => preFlightData().qualifications.certs || []
    const unqualified = () => preFlightData().unqualified || {certs: [], itemcodes: [], promos: []}
    const validated = () => preFlightData().certifying_total === preFlightData().qualified_total
    const isRanchCertified = (preFlightData().certifying_total === 0) || (preFlightData().qualified_total === 0) || (count(group?.selected_certs) === 0)

    return (
        <div className="">
            {
                preFlight.ready? 
                <>
                    { wizard === 'preflight'?
                    <>
                        <Typography variant="h6"><i className="fas fa-tasks"></i>&nbsp;{ environment.preFlightTitle }: "{ group.group_name }"</Typography>
                        <hr style={{width: '100%'}} />
                        <PreFlightWindow group={group} certs={certs} unqualified={unqualified} validated={validated} preFlightData={preFlightData} />
                        <div className="d-flex justify-content-center gapped">
                            <Button variant="contained" onClick={ () => events.closeEvent() }><ButtonBack text="Cancel" /></Button>
                            <Button variant="contained" disabled={preFlightData().counts.unable_to_certify !== 0} onClick={ () => setWizard('certify') }><ButtonForward /></Button>
                        </div>
                    </> : null }

                    { wizard === 'certify'?  
                    <>
                        <Typography variant="h6">{isRanchCertified? 'Ranch-Certify' : 'Lock'} "{ group.group_name }"?</Typography>
                        <hr style={{width: '100%'}} />
                        <ContinuePreFlightWindow group={group} preFlightData={preFlightData} />
                        <div className="pt-4 align-middle">
                            <div className="col-count-2 col-c1-md gapped">
                                <div className="align-middle">
                                    <Button variant="outlined" onClick={() => {
                                        if(events.closeEvent) {
                                            events.closeEvent()
                                        }
                                        setGid('')
                                    }}><ButtonBack text="Cancel" /></Button>
                                </div>
                                <Button variant="contained" className="corp" onClick={() => {
                                    if(events.submitEvent)
                                        events.submitEvent(isRanchCertified)
                                }}>Yes, {isRanchCertified? "Certify" : "Commit Group" }</Button>
                            </div>
                        </div>
                    </>
                    : null }
                </>
                : null
            }
        </div>
    )
}