import { useEffect, useState } from "react";
import { UserToolsTagManagerCreateService, UserToolsTagManagerGetService, UserToolsTagManagerUpdateService } from "./tags.services";
import { Button, TextField } from "@mui/material";
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface";

interface IUserToolsTagManagerUploaderBlock
{
    handleChange?: (e: any) => void;
}

interface IUserToolsTagManagerUploader extends IUserToolsTagManagerUploaderBlock
{
    gid?: string;
    useForm?: boolean;
}

const UserToolsTagManagerUploader = ({ gid, useForm = false }: IUserToolsTagManagerUploader) => {
    const [ form, setForm ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setForm({...form, data: {...form.data, [name]: value}})
    }

    const onSubmitEvent = (e: any) => {
        e.preventDefault()
        const f: any = {...form?.data}
        if(gid)
            f.gid = gid
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            const action = f?.ID? UserToolsTagManagerUpdateService(f) : UserToolsTagManagerCreateService(f)
            action.then(r => {
                if(r?.success) {
                    setState({...ComponentStateReadyDto, data: r.data})
                } else {
                    setState(ComponentStateReadyDto)
                }
            })
        }
    }

    useEffect(() => {
        if(!form?.ready && useForm) {
            if(!form?.loading) {
                setForm(ComponentStateLoadingDto)
                UserToolsTagManagerGetService().then(r => {
                    if(r?.success) {
                        setForm({...ComponentStateReadyDto, data: r.data})
                    } else {
                        setForm(ComponentStateReadyDto)
                    }
                })
            }
        }
    }, [ form?.ready, useForm ]);

    return (
        useForm?
        <form onSubmit={onSubmitEvent}>
            <UserToolsTagManagerUploaderBlock handleChange={handleChange} />
            <div className="align-middle mt-4">
                <Button variant="contained" color="primary" type="submit">Submit</Button>
            </div>
        </form> : <UserToolsTagManagerUploaderBlock handleChange={handleChange} />
    );
}

export default UserToolsTagManagerUploader;


export const UserToolsTagManagerUploaderBlock = ({ handleChange }: IUserToolsTagManagerUploaderBlock) => {
    const [ toggle, setToggle ] = useState<boolean>(false)
    return (
        <>
        <div className="align-middle">
            <div className="col-count-2 gapped mb-4">
                <Button variant={ !toggle? "contained" : "outlined" } type="button" onClick={() => setToggle(false)}>Add Using Range</Button>
                <Button variant={ toggle? "contained" : "outlined" } color="primary" type="button" onClick={() => setToggle(true)}>Add Using Commas</Button>
            </div>
        </div>
        { toggle && (
            <div>
                <TextField
                    name="tag_list"
                    rows={3}
                    multiline
                    fullWidth
                    onChange={handleChange}
                    placeholder="Example: 810000000000001,810000000000002,...etc"
                    required
                />
            </div>
        )}
        { !toggle && (
            <div className="align-middle">
                <div className="col-count-2 gapped">
                    <TextField label="Start" name="tag_start" onChange={handleChange} placeholder="Example: 810000000000001" required />
                    <TextField label="End" name="tag_end" onChange={handleChange} placeholder="Example: 810000000000020" required />
                </div>
            </div>
        )}
        </>
    )
}