import { useContext, useEffect } from "react"
import { AlertBase, AlertDanger, AlertSuccess } from "../views/alerts"
import { AlertContext } from "../../../providers/alert.provider"
import { empty } from "../../../helpers/util.helper"

export const UiNotificationsAlertsComponent = () => {
    const { msg, type, iconType, closeable, setMsg, autoClose, setAutoClose, setType } = useContext(AlertContext)

    useEffect(() => {
        if(autoClose) {
            const value: number = typeof autoClose === "number"? autoClose : 5000
            setTimeout(() => {
                setMsg('')
                setType(false)
                setAutoClose(undefined)
            }, value);
        }
    }, [])

     return (
        !empty(msg)? 
        <>
        { (type && !iconType)? <AlertSuccess text={msg} onCloseEvent={() => setMsg('')} /> : null }
        { (!type && !iconType)? <AlertDanger text={msg} onCloseEvent={() => setMsg('')} /> : null }
        { (iconType)? <AlertBase text={msg} type={iconType} buttoned={closeable} onCloseEvent={() => setMsg('')}  /> : null }
        </> : null
    )
}