import { useState } from "react"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"
import { BreedsGetAvailableService } from "../services/breeds"



export const useBreedsHook = () => {
    const [ breedsState, setBreedsState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ breeds, setBreeds ] = useState<any>({breeds: [], myBreeds: []})
    if(!breedsState.ready) {
        if(!breedsState.loading) {
            setBreedsState(ComponentStateLoadingDto)
            BreedsGetAvailableService().then(r => {
                if(r.success) {
                    setBreeds(r.data)
                }
                setBreedsState(ComponentStateReadyDto)
            })
        }
    }

    return {
        breedsState, setBreedsState, breeds
    }
}