import { useContext, useEffect, useState } from "react"
import { GroupLock, Herd, InventoryPreflightService } from "../services/herd-groups"
import { ISuccessResponse, SuccessResponse } from "../interfaces/success.response.interface"
import { IResponse } from "../interfaces/response.interface"
import { ModalContext } from "../providers/modal.provider"
import { ApplicationContext } from "../providers/application.provider"
import { IUseHerdLockButtonHookEvents, IUseHerdLockHookResponse } from "./herd-hook/herdlock.interface"
import { LockWindowModal } from "./herd-hook/lock-modal-window"
import { empty } from "../helpers/util.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"

export const usePreflightHook = () => {
    const [ preFlight, setPreFlight ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ pfGid, setPfGid ] = useState<string>('')

    if(!preFlight.ready && !empty(pfGid)) {
        if(!preFlight.loading) {
            setPreFlight(ComponentStateLoadingDto)
            InventoryPreflightService(pfGid).then(r => {
                setPreFlight({ ...ComponentStateReadyDto, data: r.data })
            })
        }
    }

    useEffect(() => {
         if(!empty(pfGid)) {
            setPreFlight(ComponentStateDto)
         }
    }, [ pfGid ])

    return {
        pfGid, setPfGid, preFlight
    }
}

export const useHerdLockHook = (gid?: string): IUseHerdLockHookResponse => {
    const [ groupId, setGroupId ] = useState<string>(gid || '')
    const [ onSuccessEvent, setOnSuccessEvent ] = useState<any>(() => {})

    const [ lockState, setLockState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ lock, setLock ] = useState<boolean>(false)
    
    const [ success, setSuccess ] = useState<ISuccessResponse>(SuccessResponse)
    const [ data, setData ] = useState<any>()
    const { setModalState } = useContext(ModalContext)
    const { setCurrentApp } = useContext(ApplicationContext)

    useEffect(() => {
        if(lock && groupId !== '') {
            if(!lockState.ready) {
                if(!lockState.loading) {
                    setLockState(ComponentStateLoadingDto)
                    GroupLock<IResponse<any>>(groupId).then((r) => {
                        let msg = 'Your group has successfully locked.'
                        let success = false
                        if(r.success) {
                            success = true
                            setData(r.data)
                            setCurrentApp((arr: any) => ({...arr, answers: r.data}))
                        } else {
                            msg = r.error || 'An error occurred'
                        }
                        setLock(false)
                        setSuccess({msg, success})
                        setLockState(ComponentStateReadyDto)
                        setModalState(ComponentStateReadyDto)
                        if(onSuccessEvent) {
                            onSuccessEvent()
                            setOnSuccessEvent(() => {})
                        }
                    })
                }
            }
        } else {
            // This needs to make the lock state ready to accept new locks
            if(!lock && lockState.ready) {
                setLockState(ComponentStateDto)
            }
        }
    }, [ lock ])
    
    return {
        lockState,
        lock,
        setLock,
        setGroupId,
        success,
        setSuccess,
        data,
        groupId,
        setOnSuccessEvent
    }
}

interface IHerdLockButtonHook
{
    setModalActive: any
    setModalState: any
    setModalAttr: any
    setModalEvent: any
    setModalBody: any
    modalState?: IComponentState<any>
}

export const useHerdLockButtonHook = ({ setModalActive, setModalState, setModalAttr, setModalEvent, setModalBody }: IHerdLockButtonHook) => {
    const [ group, setGroup ] = useState<any>(undefined)
    const [ triggerLock, setTriggerLock ] = useState<boolean>(false)
    const [ modalReady, setModalReady ] = useState<boolean>(false)
    const [ renderLockModal, setRenderLockModal ] = useState<any>(() => {})

    const [ events, setEvents ] = useState<IUseHerdLockButtonHookEvents>({
        openEvent: () => {},
        closeEvent: () => {},
        submitEvent: () => {},
    })
    const { setPfGid, preFlight, pfGid } = usePreflightHook()

    const render = () => {
        if(events.openEvent) {
            events.openEvent()
        }
        setModalState((arr: any) => ({...arr, loading: false}))
        setModalAttr({style: { maxWidth: 500, minWidth: 400 }})
        if(setModalEvent && events.closeEvent) {
            setModalEvent(() => {
                events.closeEvent()
                setPfGid('')
            })
        }
        setModalBody(<LockWindowModal preFlight={preFlight} group={group} setGid={setPfGid} events={events} />)
        setModalActive(true)
    }

    useEffect(() => {
        if(group?.ID && pfGid !== group?.ID) {
            setPfGid(group.ID)
            setRenderLockModal(() => {})
            setModalReady(false)
        }
    }, [ group, pfGid ])

    useEffect(() => {
        if(triggerLock && preFlight.ready) {
            setTriggerLock(false)
            setRenderLockModal(() => render)
            setModalReady(true)
        }
    }, [ triggerLock, preFlight.ready ])

    return {
        setEvents, setTriggerLock, setGroup, renderLockModal, modalReady
    }
}

export const useHerdHook = (gid?: string) => {
    const [ refreshHerd, setRefreshHerd ] = useState<boolean>(false)
    const [ groupId, setGroupId ] = useState<string>(gid || '')
    const [ herd, setHerd ] = useState<any[]>([])
    const [ herdState, setHerdState ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(refreshHerd) {
            setRefreshHerd(false)
            setHerdState(ComponentStateDto)
        }
    }, [ refreshHerd ])

    useEffect(() => {
        if(!empty(groupId) && !herdState.ready) {
            if(!herdState.loading) {
                setHerdState(ComponentStateLoadingDto)
                Herd(groupId).then(r => {
                    // set the rows
                    if(r.success) {
                        // Set the rows
                        setHerd(r.herd)
                    }
                    setHerdState(ComponentStateReadyDto)
                })
            }
        }
    }, [ groupId, refreshHerd ])

    return {
        herd, setHerd, setHerdState, herdState, setGroupId, setRefreshHerd
    }
}