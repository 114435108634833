export const environment = {
    companyName: import.meta.env.VITE_COMPANY_NAME as string,
    compantSite: import.meta.env.VITE_FRONTENDPOINT as string,
    apiFrontend: import.meta.env.VITE_API_FRONTENDPOINT as string,
    api: import.meta.env.VITE_APIENDPOINT as string,
    defaultHome: import.meta.env.VITE_DEFAULT_HOME as string,
    account: import.meta.env.VITE_ACCOUNT_LINK as string,
    ipfsGateway: import.meta.env.VITE_IPFS_GATEWAY as string,
    preFlightTitle: 'CattleProof PVP Scan',
    appVersion: import.meta.env.VITE_VERSION_APP as string,
} 