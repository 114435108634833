export default interface IComponentState<T>
{
    loading: boolean
    ready: boolean
    data?: T
}

export const ComponentStateDto: IComponentState<any> = {
    loading: false,
    ready: false
}
export const ComponentStateLoadingDto: IComponentState<any> = {
    loading: true,
    ready: false
}
export const ComponentStateReadyDto: IComponentState<any> = {
    loading: false,
    ready: true
}