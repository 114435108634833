
import { FarFaBell } from "../../../fontawesome"
import { useContext, useState } from "react"
import { ModalContext } from "../../../../../providers/modal.provider"
import { signOut } from "../../../../logout"
import Notification, { INotify } from "../../../../../services/notification"
import NotificationsComponent from "../../../notifications"
import IComponentState from "../../../../../interfaces/component-state.interface"
import __ from "../../../../../services/translator"
import { SSOHook } from "../../../../../hooks/sso.hook"
// import { AccountContext } from "../../../providers/account.provider"

type INavMainCustomer = {
    setNotificationCount: any,
    eventController: any,
    modalActiveController: any,
    admin: boolean
}

export default function NavMainCustomer(props: INavMainCustomer)
{
    const notes: INotify = {count:0, notes: []}
    const [ notifications, setNotifications ] = useState(notes)
    const [ notificationsState, setNotificationsState ] = useState<IComponentState<any>>({ready: false, loading: false})
    const { setModalActive, setModalBody, setModalTitle, setModalEvent } = useContext(ModalContext)
    const { setTriggerSso } = SSOHook()

    //const { loggedIn } = useContext(AccountContext)
    const fireNotifications = () => {
        setModalTitle(__('Notifications', 'notifications'))
        setModalBody(<NotificationsComponent setNotificationCount={props.setNotificationCount} />)
        // Requires two because it's firing the first on set
        if(setModalEvent)
            setModalEvent(() => () => {
                setModalActive(false)
            })
        setModalActive(true)
    }

    if(!notificationsState.ready) {
        if(!notificationsState.loading) {
            setNotificationsState(arr => ({...arr, loading: true}))
            Notification({count: true}).then(r => {
                // Send back the count to parent
                props.setNotificationCount(r.count)
                // Set the notifications for this component
                setNotifications(r)
                // Mark ready
                setNotificationsState({ready: true, loading: false})
            })
        }
    }
    //const url: string = import.meta.env.VITE_ACCOUNT_LINK as string
    
    return (
        <ul className="list-menu">
            <li>
                <a href="#notifications" onClick={ fireNotifications }><FarFaBell toggled={notifications.count > 0} styling={notifications.count > 0? 'active' : 'inactive' } />&nbsp;{__('Notifications', 'notifications')}</a>
            </li>
            {!props.admin?
            <>
                <li>
                    <div><a href="#" onClick={() => setTriggerSso(true)} ><i className="fas fa-user"></i>&nbsp;{__('Account', 'account')}</a></div>
                </li>
            </>
            : null }

            <li>
                <div><a href="#" onClick={() => signOut() }><i className="fas fa-sign-out-alt"></i>&nbsp;{__('Sign Out', 'signout')}</a></div>
            </li>
        </ul>
    )
}