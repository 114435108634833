import { useContext, useEffect, useState } from "react"
import { GroupStatusApproved, GroupStatusCertifiedAt, GroupStatusPending, GroupStatusReady, groupStatusEnum } from "../../../enums/group.status.enum"
import { Button, Tooltip, Typography } from "@mui/material"
import { CowHeadIconStatic } from "../../ui/fontawesome"
import { ModalContext } from "../../../providers/modal.provider"
import { UploadCsv } from "../../upload"
import { ApplicationContext } from "../../../providers/application.provider"
import { CalvingGroupDeleteService } from "../../../services/calving.groups.service"
import { ComponentStateDto } from "../../../interfaces/component-state.interface"
import { usePreflightHook } from "../../../hooks/herd.hook"
import { AccountContext } from "../../../providers/account.provider"
import { isAdmin } from "../../../helpers/account"
import { CheckoutContext } from "../../../providers/checkout.provider"
import { LockWindowModal } from "../../../hooks/herd-hook/lock-modal-window"
import { useNavigate } from "react-router-dom"
import ButtonAdmin from "../../ui/buttons/button-admin"

export const HerdGroupToolActions = ({
    status,
    group_name,
    ID,
    paid,
    setGroupState,
    setSuccess,
    setLock,
    setGroupId,
    herd_count,
    row,
    lockState
}: any) => {
    const [ toggleLock, setToggleLock ] = useState<boolean>(false)
    const [ stateGid ] = useState<string>(ID)
    const { setModalActive, setModalAttr, modalState, setModalEvent, setModalBody } = useContext(ModalContext)
    const { setToggleForm, setFormContent, setFormId, setAppLoaded, setFormWrapperStyle, setReload } = useContext(ApplicationContext)
    const { account } = useContext(AccountContext)
    const { setOrder, setOnCancelEvent } = useContext(CheckoutContext)
    const [ reloadOnLock, setReloadOnLock ] = useState<boolean>(false)

    const nav = useNavigate()

    const { setPfGid, preFlight } = usePreflightHook()
    
    const initPage = (gid: string) => {
        setAppLoaded(false)
        setFormId(gid)
        //setToggleForm(true)
        setReload(true)
    }
    
    const render = (events: any, group: any) => {
        initPage(stateGid)
        setToggleForm(true)
        setFormContent(<LockWindowModal preFlight={ preFlight } group={ group } setGid={ setPfGid } events={ events } />)
    }

    const onLockEvent = () => {
        setPfGid(stateGid)
    }

    const closeButtonEvent = () => {
        setReload(true)
        setGroupState(ComponentStateDto)
    }

    const onAddHerd = () => {
        if(setModalEvent)
            setModalEvent(() => closeButtonEvent)
        setModalActive(true)
        setModalBody(
            <UploadCsv
                title={`Upload to "${group_name}"`}
                group={ stateGid }
                success={setSuccess}
                alertingIgnoreInternal={true}
                onCloseEvent={closeButtonEvent}
            />)
    }

    const onDeleteGroup = () => {
        CalvingGroupDeleteService({gid: stateGid}).then(r => {
            if(r.success) {
                setFormId('')
                setGroupState(ComponentStateDto)
                setModalActive(false)
            }
        })
    }
    
    const onDeleteHerd = () => {
        setModalActive(true)
        setModalAttr({style: { maxWidth: 500, minWidth: 400 }})
        setModalBody(
        <div className="align-middle">
            <Typography variant="h5">Are you sure?</Typography>
            <p className="mt-4 text-center">When you delete the group, you are deleting all the animals inside the calving group as well.</p>
            <div className="align-middle pt-4">
                <Button variant="contained" className="corp" onClick={onDeleteGroup}>Yes, Delete</Button>
            </div>
        </div>)
    }

    const onEditGroup = () => {
        // setFormContent(<ModuleComponent resetGroup={setGroupState} />)
        initPage(stateGid)
        nav(`/edit/group/${ID}`)
    }
    
    const onEditAnimalList = () => {
        setFormWrapperStyle('span3')
        // setFormContent(<AnimalEditorComponent groupId={ stateGid } />)
        nav(`/edit/animal-list/${stateGid}`)
        initPage(stateGid)
    }

    useEffect(() => {
        if(toggleLock) {
            setToggleLock(false)
            setModalActive(false)
            closeButtonEvent()
        }
    }, [ modalState.ready ])

    useEffect(() => {
        if(preFlight.ready) {
            render({
                openEvent: () => {},
                closeEvent: () => {
                    setToggleForm(false)
                    closeButtonEvent()
                },
                submitEvent: () => {
                    setGroupId(stateGid)
                    setLock(true)
                    setToggleForm(false)
                    setReloadOnLock(true)
                }
            }, row)
        }
    }, [ preFlight.ready ])

    useEffect(() => {
        if(reloadOnLock && lockState.ready) {
            closeButtonEvent()
            setReloadOnLock(false)
        }
    }, [ reloadOnLock, lockState.ready ])

    return (
    (paid && status === 1)?
    <div className="d-flex justify-content-end" style={{width: '100%'}}>
        <Button
            className="no-appearance align-middle"
            onClick={ onEditAnimalList }
        >
            <span>Review&nbsp;<CowHeadIconStatic size='md' color="black" /></span>
        </Button>
    </div>
    :
    <div className="d-flex justify-content-end" style={{width: '100%'}}>
        <GroupStatusCertifiedAt group={{}}>
            <div className={`gapped-half col-count-${account.isAdmin? '7' : '6'}`}>
                <Tooltip title="Add Animals" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusReady status={account?.account_type === 'buyer'? 1 : status}>
                            <Button
                                className="no-appearance"
                                onClick={ onAddHerd }
                            >
                                <i className="fas fa-file-upload fa-lg"></i>
                            </Button>
                        </GroupStatusReady>
                    </div>
                </Tooltip>
                <Tooltip title="Edit Animals" enterDelay={1000}>
                    <div>
                        <GroupStatusReady status={account?.account_type === 'buyer'? 0 : status} enableOverrideOn={() => account.isAdmin || account?.account_type === 'producer' }>
                            <Button
                                className="no-appearance"
                                onClick={ onEditAnimalList }
                            >
                                <CowHeadIconStatic size='md' color="black" />
                            </Button>
                        </GroupStatusReady>
                    </div>
                </Tooltip>
                <Tooltip title="Edit cattle group" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusReady status={status}>
                            <Button
                                disabled={ account?.account_type === 'buyer' }
                                className="no-appearance"
                                onClick={ onEditGroup }>
                                    <i className="far fa-edit fa-lg"></i>
                            </Button>
                        </GroupStatusReady>
                    </div>
                </Tooltip>
                <Tooltip title="Submit herd for review" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusReady status={status} requires={() => herd_count > 0 }>
                            <Button
                                className="no-appearance"
                                onClick={ onLockEvent }
                            >
                                <i className="far fa-check-circle fa-lg"></i>
                            </Button>
                        </GroupStatusReady>
                    </div>
                </Tooltip>
                <Tooltip title="Pay for permanent, public-accessable, storage" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusApproved status={status}>
                            <Button
                                className="no-appearance"
                                disabled={ !(status === groupStatusEnum.approved && paid === false) || account?.account_type === 'buyer' }
                                onClick={() => {
                                    setOrder({...row, orderType: 'herd'})
                                    setOnCancelEvent(() => () => setOrder({}))
                                }}
                            >
                                <i className="fas fa-shopping-basket fa-lg"></i>
                            </Button>
                        </GroupStatusApproved>
                    </div>
                </Tooltip>
                <Tooltip title="Delete Group" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusReady status={status} requires={ () => !isAdmin() }>
                            <Button
                                disabled={ account?.account_type === 'buyer' }
                                className="no-appearance"
                                onClick={onDeleteHerd}>
                                    <i className="fas fa-trash-alt fa-lg"></i>
                            </Button>
                        </GroupStatusReady>
                    </div>
                </Tooltip>
                { account.isAdmin? 
                <Tooltip title="Admin View" enterDelay={1000}>
                    <div className="pt-1">
                        <GroupStatusPending status={status}>
                            <ButtonAdmin onClickEvent={ onEditGroup } text=''></ButtonAdmin>
                        </GroupStatusPending>
                    </div>
                </Tooltip> : null
                }
            </div>
        </GroupStatusCertifiedAt>
    </div>
    )
}

