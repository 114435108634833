import { useState } from "react"
import { IPdfCertificateResponse, PdfCertificateService } from "../../services/pdf.service"
import { environment } from "../../environmentals/config"
import { toDate } from "../../helpers/util.helper"
import { BootStrapSpinner } from "../../components/ui/views/spinner"
import Template from "../../templates/cattleproof"

interface ICertificatePage
{
    certsLoading: boolean
    certsReady: boolean
    certs: IPdfCertificateResponse[]
}

export default function CertificatesPage()
{
    const [ cert, setCert ] = useState<ICertificatePage>({
        certsLoading: false,
        certsReady: false,
        certs: []
    })

    if(!cert.certsReady) {
        if(!cert.certsLoading) {
            setCert(arr => ({...arr, certsLoading: true}))
            PdfCertificateService('all').then(r => {
                setCert({certs: r.data, certsLoading: false, certsReady: true})
            })
        }
    }
    
    return (
        <Template
            title={<><i className="fas fa-award"></i>&nbsp;Certified Groups</>}
            description={''}
        >
            { !cert.certsReady? <BootStrapSpinner /> : null }
            { cert.certsReady && cert.certs.length === 0? <div className="alert alert-secondary">There are no certifications on any animal groups yet.</div> : null }
            { cert.certsReady && cert.certs.length > 0? 
            <>
            <p className="my-4">Below are all the certified herds. Click to download the certificate related to the herd group.</p>
            <table className="standard">
                <tbody>
                    <tr>
                        <th>Group Name</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Animals</th>
                        <th></th>
                    </tr>
                    { cert.certsReady && cert.certs? cert.certs.map((v, k) => {
                            return (
                    <tr key={k} className="border-bottom">
                        <td>{ v.group_name }</td>
                        <td className="text-center">{ toDate(v.certified_at) }</td>
                        <td className="text-center">{ v.animals }</td>
                        <td style={{textAlign: 'right'}}>
                            <a href={`${environment.api}/generate/pdf/${v.ID}`} target="_blank" referrerPolicy="no-referrer" className="corp-mui outlined size-small"><i className="far fa-file-pdf m-0 p-0 fa-lg" style={{position: 'relative', top: 3}}></i>&nbsp;Download</a></td>
                    </tr>
                            )
                        }) : null
                    }
                </tbody>
            </table>
            </> : null }
        </Template>
    )
}