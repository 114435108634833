import { Button, TextField, Tooltip } from "@mui/material"
import { memo, useContext, useEffect, useState } from "react"
import { AnimalSearchGet, IAnimalSearchGet } from "../../services/animal-search"
import { AlertYinYang } from "../../components/ui/views/alerts"
import { useNavigate, useSearchParams } from "react-router-dom"
import { BootStrapSpinner, CircularProgress } from "../../components/ui/views/spinner"
import { CertificationObjListService, ICertificationListObj } from "../../services/certifications.service"
import { AlertContext } from "../../providers/alert.provider"
import { IAnyType } from "../../interfaces/form-builder.interface"
import { environment } from "../../environmentals/config"
import { AccountContext } from "../../providers/account.provider"
import { dateNameToDate } from "../../helpers/util.helper"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../interfaces/component-state.interface"
import TemplatesCattleProofCenterBlock from "../../templates/cattleproof/center-block"
import ImageCompanyLogo from "../../components/ui/image-company-logo"
import ButtonDelete from "../../components/ui/buttons/button-delete"
import ButtonSearch from "../../components/ui/buttons/button-search"
import StandardTable from "./standard-table"
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ButtonBack from "../../components/ui/buttons/button-back"
import __ from "../../services/translator"
import './styles.scss'

const Explorer = memo(function Explorer()
{
    const [ search ] = useSearchParams()
    const [ baseSearch, setBaseSearch ] = useState(false)
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateDto)
    const [ certList, setCertList ] = useState<IComponentState<ICertificationListObj>>(ComponentStateDto)

    const [ eid, setEid ] = useState<string>(search.get('eidif') || '')
    const [ cid, setCid ] = useState<string>(search.get('cidif') || '')
    const [ successMessage, setSuccessMessage ] = useState<string>('')
    const [ errorMessage, setErrorMessage ] = useState<string>('')
    const [ submitted, setSubmitted ] = useState<boolean>(false)
    const [ disabledField, setDisabledField ] = useState<any>({eid: false, cid: false})
    const [ animalData, setAnimalData ] = useState<IAnimalSearchGet>({})

    const { setMsg, setType } = useContext(AlertContext)
    const { account } = useContext(AccountContext)
    const nav = useNavigate()

    if(!certList.ready) {
        if(!certList.loading) {
            setCertList(ComponentStateLoadingDto)
            CertificationObjListService().then(r => {
                if(!r.success) {
                    setMsg(r.error || 'An error occurred')
                    setType(false)
                }
                setCertList({...ComponentStateReadyDto, data: r.data || {}})
            })
        }
    }

    const resetMessages = () => {
        setErrorMessage('')
        setSuccessMessage('')
    }

    const clearSearch = () => {
        setEid('')
        setCid('')
        setDisabledField({cid: false, eid: false})
        resetMessages()
        resetSearch()
    }

    const resetSearch = () => {
        setAnimalData({})
        setSubmitted(false)
    }

    const id: string = (eid || cid).replace(' ', '')
    if(submitted) {
        if(!state.loading) {
            setState(ComponentStateLoadingDto)
            if(id !== '') {
                AnimalSearchGet(id, `${eid !== ''? 'e' : 'c'}id`).then(r => {
                    if(!r.success) {
                        setErrorMessage(r.error || 'An error occurred' )
                        resetSearch()
                    } else {
                        setAnimalData(r || {})
                        setSubmitted(false)
                    }
                    setState(ComponentStateDto)
                })
            } else {
                setErrorMessage('Please supply a valid CID or PCT in order to find the animal.')
                setSubmitted(false)
                setState(ComponentStateDto)
            }
        }
    }

    const numberMask = (value: string) => {
        const v = value.replace(/[^\d]/gi, '')
        if(v.length <= 15)
            return v
        return v.substring(0, 15)
    }

    const openExternalLink = (link?: string) => {
        const conf: boolean = window.confirm('You will be redirected to an external website. Continue?')
        if(conf)
            window.open(link || animalData.url, '_blank')
    }

    const renderCheckDate = (v: string) => <><CheckCircleOutlineIcon color="success" />&nbsp;{ dateNameToDate(v) } </>

    const repKeys = {
        'SAV Certified': <Tooltip title={certList?.data?.sav?.description || 'Source and Age Verification'}><strong className="pointer">SAV Certified <InfoIcon /></strong></Tooltip>,
        'IBG Angus Certified': <Tooltip title={certList?.data?.ibg?.description || 'IBG Real Angus Beef'}><strong className="pointer">IBG Angus Certified <InfoIcon /></strong></Tooltip>,
        'Real Angus Certified': <Tooltip title={certList?.data?.rab?.description || 'Real Angus Certified'}><strong className="pointer">Real Angus Certified <InfoIcon /></strong></Tooltip>,
        'Certified Born In The USA': <Tooltip title={certList?.data?.usa?.description || 'Certified Born In The USA'}><strong className="pointer">Born In The USA Certified<InfoIcon /></strong></Tooltip>,
        'US CattleTrace': <Tooltip title={certList?.data?.cattletrace?.description || 'The producer-led organization for animal disease traceability'}><strong className="pointer"><a href="https://www.uscattletrace.org/" target="_blank" referrerPolicy="same-origin">US CattleTrace</a> <InfoIcon /></strong></Tooltip>,
        'Last CID': 'Previous Animal Data',
    }

    const repValues: IAnyType<string | { type: string, value: any }> = {
        'last_CID': {
            type: 'func',
            value: (link: string) => {
                return (
                <div className="col-count-2 gapped col-c1-sm">
                    <Button variant="outlined" onClick={() => {
                        clearSearch()
                        setCid(link)
                        setSubmitted(true)
                        nav(`/explorer/?cidif=${link}`)
                    } }>VIEW IN EXPLORER</Button>
                    <Button variant="outlined" onClick={() => openExternalLink(`${environment.ipfsGateway.replace('~cid~', link)}`)}>VIEW ON IPFS</Button>
                </div>)
            }
        },
        'US_CattleTrace': {
            type: 'func',
            value: renderCheckDate
        },
        'SAV Certified': {
            type: 'func',
            value: renderCheckDate
        },
        'IBG Angus Certified': {
            type: 'func',
            value: renderCheckDate
        },
        'Real Angus Certified': {
            type: 'func',
            value: renderCheckDate
        },
        'Certified Born In The USA': {
            type: 'func',
            value: renderCheckDate
        },
        'Producer Certified Date': {
            type: 'func',
            value: renderCheckDate
        },
    }

    useEffect(() => {
        if((search.get('eidif') || search.get('cidif')) && !baseSearch) {
            setBaseSearch(true)
            setSubmitted(true)
        }
    }, [ search.get('eidif'), search.get('cidif'), baseSearch])

    useEffect(() => {
        document.getElementById('logged-out-state')?.classList.remove('align-middle')
    })

    return (

        <TemplatesCattleProofCenterBlock
            addClass="full-height cattle-bkg-001 clouded"
            startClass="start2 bkg-white shadowed rel"
            id="explorer"
        >
            <div className="bkg-corp explorer-mast-head col-count-2">
                <div className="p-2 align-middle">
                    <ImageCompanyLogo />
                </div>
                <div className={`pt-3 m-2 ${account?.ID? 'explorer-right-nav col-count-2 gapped col-c1-sm' : ''}`}>
                    <div>
                        <h3 className="text-white">CattleProof Verified Explorer</h3>
                        <p className="text-white">Get started by searching for an animal Electronic ID or a permanent storage Content ID.</p>
                    </div>
                    {account?.ID?
                    <div className="align-middle">
                        <Button variant="outlined" className="corp-white mb-4 py-3"><a className="no-appearance" href={environment.defaultHome}><ButtonBack text="Herd Manager" /></a></Button>
                    </div> : null }
                </div>
            </div>
            <div style={{position: 'absolute', right: 0, left: 0}} className="p-2">
                <AlertYinYang success={successMessage} error={errorMessage} />
            </div>
            {  certList.ready?
            <div className="mt-4">
                <div className="col-count- col-c1-lg gapped eid-cid">
                    <TextField
                        className="span1-lg"
                        disabled={ disabledField.eid || submitted }
                        value={eid}
                        helperText="Please enter the Animal ID (Program Compliant Tag)"
                        label="Animal Tag ID (PCT)"
                        onChange={(e: any) => {
                            const v = numberMask(e.target.value)
                            setEid(v)
                            setCid('')
                            setDisabledField({cid: (v !== ''), eid: false})
                            resetMessages()
                            resetSearch()
                        }}
                        inputProps={{ maxLength: 15 }}
                    />
                        
                    <div className="align-middle my-3 start1-lg">OR</div>
                    <TextField className="span1-lg start1-lg" disabled={ disabledField.cid || submitted } value={cid} label="Content ID (CID)" onChange={(e: any) => {
                        setEid('')
                        setCid(e.target.value)
                        setDisabledField({cid: false, eid: (e.target.value !== '')})
                        resetMessages()
                        resetSearch()
                    }} />
                </div>
                <div className="search-bar align-middle py-4">
                    { !submitted?
                        <div className="col-count-2 gapped col-c1-md">
                            <Button disabled={id === ''} variant="outlined" className="corp" onClick={clearSearch}><ButtonDelete text={__('Clear', 'clear')} /></Button>
                            <Button variant="contained" className="corp" onClick={() => setSubmitted(true)}><ButtonSearch text={__('Find Animal', 'findanimal')} /></Button>
                        </div> : <BootStrapSpinner />
                    }
                </div>
                
                { animalData.found?
                <div className="all-data mt-5">
                    <h4><strong>Animal Information</strong></h4>
                    <h5>Program Compliant Tag (PCT) ID: { animalData.data.eid || animalData.data['PCT ID'] || '' }</h5>
                    <h5>Blockchain ID (CID): <a href="#" onClick={() => openExternalLink()}>{ animalData.cid }</a></h5>
                    <h5>IPFS Link: <a href="#" onClick={() => openExternalLink()}>{ animalData.url }</a></h5>
                    { animalData.data? <StandardTable data={ animalData.data || [] } skip={['uid','gid','ID']} replaceKeys={repKeys} replaceValues={ repValues } /> : null }
                    <div className="align-middle pt-5">
                        <Button disabled={id === ''} variant="outlined" className="corp" onClick={clearSearch}><ButtonDelete text={__('Clear', 'clear')} /></Button>
                    </div>
                </div> : null
                }
            </div> : <CircularProgress />
            }
        </TemplatesCattleProofCenterBlock>
    )
})

export default Explorer