import { BaseDto } from "./base.dto"

export class CattleFormSingleViewDto extends BaseDto
{
    protected buttonActions: any = null
    public ID: string = '0'
    public id: string = '0'
    public uid: string = '0'
    public gid: string = '0'
    public ranch_name: string = ''
    public ranch_tag_id: string = ''
    public eid: string = ''
    public breed: string = ''
    public birth_date: string = ''
    public tag_color: string = ''
    public visual_tag: string = ''
    public location: string = ''
    public harvest_date: string = ''
    public death_date: string = ''
    public breed_certified_at: string = ''
    public eid_certified_at: string = ''
    public ear_notches_certified_at: string = ''
    public brands_certified_at: string = ''
    public metalplastic_clip_certified_at: string = ''
    public other_tag_certified_at: string = ''
    public ranch_certified_at: string = ''
    public status: number = 0
    public created_at: string = ''
    public deleted_at: string = ''
    public decentralized_at: string = ''
    public cid: string = ''
    public archived_at: string = ''


    constructor(obj:any)
    {
        super()
        this.populate(obj, this)

        this.id = this.ID
    }
}