import { IButtonAny } from "..";
import __ from "../../../../services/translator";

export default function ButtonBack({ text }: IButtonAny)
{
    return (
        <span className="strong">
            <i className="fas fa-angle-double-left"></i>&nbsp;{ text || __('Back', 'back') }
        </span>
    )
}