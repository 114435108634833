import { useContext, useEffect, useState } from 'react'
import { LoginResponse, Credentials } from '../../interfaces/account.login.interface'
import { BootStrapSpinner } from '../ui/views/spinner'
import { AccountContext } from '../../providers/account.provider'
import { IContextChildren } from '../../interfaces/context-children.inteface'
import { Button } from '@mui/material'
import { NavMain } from '../ui/nav/nav-main'
import { NavSidebar } from '../ui/nav/nav-sidebar'
import { SsoCreateService } from '../../services/sso.service'
import { environment } from '../../environmentals/config'
import { SubscriptionPromptComponent } from '../cpv-store/subscription-prompt'
import { AlertContext } from '../../providers/alert.provider'
import { UiNotificationsAlertsComponent } from '../ui/notifications/alerts.component'
import { SystemGetService } from '../../services/settings'
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from '../../interfaces/component-state.interface'
import ViewMainWindow from '../ui/views/main-window'
import NavMobile from '../ui/nav/nav-mobile'
import WidgetCommittedHerds from '../widgets/committed-herds'
import LayoutFooter from '../ui/layout-footer'
import HttpClient from '../../helpers/client'
import useToken from '../../helpers/token'
import ImgLogo from '/src/media/images/logo.png'
import __ from '../../services/translator'
import './styles.scss'

// Fetch authentication from endpoint
const loginUser = async (credentials: Credentials) => {
    return HttpClient.init().post<LoginResponse>('auth', credentials)
}

// Login component
export default function Login({ children }: IContextChildren)
{
    const { account, accountState } = useContext(AccountContext)
    const { msg } = useContext(AlertContext)

    return (
        <>
        { !accountState.ready? <BootStrapSpinner /> : null }
        { account.ID && account.user_status === 'on'?
        <SubscriptionPromptComponent>
        <div>
            <NavMain />
            <div className="col-count-1 gapped main-stage">
                <div id="upload-page" className='col-count-2 gapped left-nav-layout'>
                    <NavSidebar  />
                    <div className={`start2 main-display-window py-3 ${account?.account_type === 'buyer'? 'buyer-view' : ''}`}>
                        <NavMobile />

                        { msg !== ''? <div><UiNotificationsAlertsComponent /></div> :  null }

                       { accountState.ready && account.ID? 
                        <div className="col-count-3 gapped col-c2-md col-c1-sm">
                            <WidgetCommittedHerds />
                        </div> : null
                        }

                        { children }
                    </div>
                </div>
            </div>
            <LayoutFooter />
        </div>
        </SubscriptionPromptComponent>
        : <LoginForm />
        }        
        </>
    )
}

export const UnEnrolledCustomer = ({children}: IContextChildren) => {
    const [ nonce, setNonce ] = useState<any>({ready: false, loading: false, token: ''})
    const { account } = useContext(AccountContext)

    const getSsoNonce = () => {
        if(!nonce.ready) {
            if(!nonce.loading) {
                setNonce((arr: any) => ({...arr, loading: true}))
                SsoCreateService().then(r => {
                    setNonce({ready: r.success? true : false, loading: false, token: r.success? r.data : '' })
                })
            }
        }
    }

    useEffect(() => {
        if(nonce.ready && nonce.token !== '') {
            window.location.href = `${environment.account}?sso=${nonce.token}`
        }
    }, [ nonce.ready, nonce.token ])

    return (
        (account?.cpv_enrolled === 1)? children : 
        <ViewMainWindow
            hideBkg={false}
            logoSize='small'
            alertBody={<span className='align-middle'>You have not been enrolled in the program.<br /><Button className='mt-4' variant='contained' disabled={nonce.loading} onClick={getSsoNonce}>Okay</Button></span>}
        >{ nonce.loading? <BootStrapSpinner /> : null }</ViewMainWindow>
    )
}

export const LoginForm = () => {
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMessage] = useState('')
    const [ loading, setLoading ] = useState({ready: false, loading: false})
    const [ state, setState ] = useState<IComponentState<{user_login: string, user_signup: string}>>(ComponentStateDto)

    const { setToken } = useToken()
    const { setAccountState, accountState, account } = useContext(AccountContext)

    const refreshErrMsg = () => {
        if(errorMsg)
            setErrorMessage('')
    }
    // On submission of login
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // Check if either values are empty
        if(username.trim() === '' || password.trim() === '') {
            setErrorMessage('Invalid username or password')
            return null
        }
        if(!loading.ready) {
            if(!loading.loading) {
                // Show loader
                setLoading(arr => ({...arr, loading: true}))
                // Fetch auth
                const token: LoginResponse = await loginUser({ username, password });
                // Set token and refresh if good
                if(token.success) {
                    setToken(token.jwt)
                    // Ready to go
                    setLoading({ready: true, loading: false})
                    // Relaunch the account login
                    setAccountState({ready: false, loading: false})
                } else {
                    // Show the error
                    token.error = (token.error !== "")? token.error : 'Invalid Username or Password'
                    setErrorMessage(token.error)
                    // Ready to go
                    setLoading({ready: false, loading: false})
                }
            }
        }
    }

    useEffect(() => {
        if(accountState.ready && !account.ID) {
            if(!state.ready) {
                if(!state.loading) {
                    setState(ComponentStateLoadingDto)
                    SystemGetService('website').then(r => {
                        if(r?.success) {
                            setState({...ComponentStateReadyDto, data: {...r.data}})
                        } else {
                            setState(ComponentStateReadyDto)
                        }
                    })
                }
            }
        }
    }, [ accountState.ready, state.ready ])

    useEffect(() => {
        if(state?.data?.user_login !== '1' && state?.ready) {
            setErrorMessage('Site is currently being updated. Please try back later.')
        }
    }, [ state?.ready ])

    return (
        <div className={`body full-height has-me nu ${account?.ID? '' : `login-align-middle`}`}>
            { !accountState.ready? <BootStrapSpinner /> : 
            <div className='full-height align-middle'>
                <div className='login-wrapper'>
                    <div className='align-middle'>
                        <img src={ ImgLogo } alt="Cattle Proof Verified Logo" className='company-logo' />
                    </div>
                    <form onSubmit={ handleSubmit } className='align-middle'>
                        <div className="error-container">
                            <div className='error-wrapper mt-1'>
                                { (errorMsg)? <div className='alert alert-danger pointer' onClick={ () => refreshErrMsg() }>{errorMsg}</div> : '' }
                            </div>
                        </div>
                        { state.ready && (
                        <>
                        <input
                            type="text"
                            placeholder={ __('Username', 'username') }
                            id='username'
                            onChange={e => { refreshErrMsg(); setUserName(e.target.value) }}
                            disabled={ state?.data?.user_login !== '1' }
                        />
                        <input
                            type="password"
                            placeholder={ __('Password', 'password') }
                            onChange={e => { refreshErrMsg(); setPassword(e.target.value)}}
                            disabled={ state?.data?.user_login !== '1' }
                        />
                        <div className='align-middle'>
                            { (loading.loading)? <BootStrapSpinner /> : <button type="submit" disabled={loading.loading || state?.data?.user_login !== '1' }>{ __('Submit', 'submit') }</button> }
                        </div>
                        </>)}
                    </form>
                </div>
            </div> }
        </div>
    )
}