import { useEffect, useState } from "react"
import { MediaGetService } from "../services/media.service"
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../interfaces/component-state.interface"

export const useUploadsHook = () => {
    const [ uploads, setUploads ] = useState<any[]>([])
    const [ gid, setGid ] = useState<string>('')
    const [ gidState, setGidState ] = useState<IComponentState<any>>(ComponentStateDto)

    useEffect(() => {
        if(gid !== '') {
            if(!gidState.ready) {
                if(!gidState.loading) {
                    setGidState(ComponentStateLoadingDto)
                    MediaGetService(gid).then(r => {
                        if(r.success)
                            setUploads(r.data)

                        setGidState(ComponentStateReadyDto)
                    })
                }
            }
        } else {
            setUploads([])
        }
    }, [ gid, gidState ])

    return {
        uploads, setUploads, setGid, gidState, setGidState
    }
}